import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import Home from './components/Home/Home';
import Login from './components/Login/Login';
import Register from './components/Register/Register'

import AppRoute from './utils/AppRoute';
import jwtDecode from'jwt-decode'  

//Redux 
import {Provider} from 'react-redux'; 
import store from './redux/store';
import {SET_AUTHENTICATED} from './redux/types';
import {logoutUser,getUserData} from './redux/actions/useractions'; 
import axios from 'axios';
import Dashboard from './components/Dashboard/Dashboard';
import CrearPerfil from './components/Register/CrearPerfil';
import LayoutD from './components/layout/LayoutD';
import RouteWithLayout from './utils/RouteWithLayout';
import Clientes from './components/Dashboard/Clientes/Clientes';
import MarketplaceClientes from './components/Dashboard/MarketplaceClientes/MarketplaceClientes';
import Cursos from './components/Dashboard/Cursos/Cursos';
import MiPerfil from './components/Dashboard/MiPerfil/MiPerfil';
import ClientePerfil from'./components/Dashboard/ClientePerfil/ClientePerfil';
import PerfilEquipo from './components/Dashboard/Equipos/PerfilEquipo';
import HomeMarket from './components/Dashboard/MarketplaceClientes/HomeMarket';
import Categorias from './components/Dashboard/MarketplaceClientes/Categorias';
import MisCotizaciones from './components/Dashboard/MarketplaceClientes/MisCotizaciones';
import CotizacionesAprobadas from './components/Dashboard/MarketplaceClientes/CotizacionesAprobadas';
import CotizacionesPublicadas from './components/Dashboard/MarketplaceClientes/CotizacionesPublicadas';
import ItemsCotizacion from './components/Dashboard/MarketplaceClientes/ItemsCotizacion';
import HomeMarketEmpresa from './components/Dashboard/MarketPlaceEmpresa/HomeMarketEmpresa';
import {Noticias} from './components/Dashboard/Noticias/Noticias';
import CategoriasMarket from './components/Dashboard/MarketPlaceEmpresa/CategoriasMarket';
import CotiPublicadas from './components/Dashboard/MarketPlaceEmpresa/CotiPublicadas';
import RespuestaCotizacion from './components/Dashboard/MarketPlaceEmpresa/RespuestaCotizacion';
import OfertasEnviadas from './components/Dashboard/MarketPlaceEmpresa/OfertasEnviadas';
import OfertasAprobadas from './components/Dashboard/MarketPlaceEmpresa/OfertasAprobadas';
import OfertasCliente from './components/Dashboard/MarketplaceClientes/OfertasCliente';
import OfertaDetails from './components/Dashboard/MarketplaceClientes/OfertaDetails';
import OfertasRechazadas from './components/Dashboard/MarketPlaceEmpresa/OfertasRechazadas';
import CotizacionesRechazadas from './components/Dashboard/MarketplaceClientes/CotizacionesRechazadas';
import EquiposAsistencial from './components/Dashboard/DashboardAsistencial/EquiposAsistencial';
import SolicitudesAsistencial from './components/Dashboard/DashboardAsistencial/SolicitudesAsistencial';
import InventarioEquipos from './components/Dashboard/DashboardAsistencial/InventarioEquipos';
import CronogramaMto from './components/Dashboard/DashboardAsistencial/CronogramaMto';
import CronogramaCal from './components/Dashboard/DashboardAsistencial/CronogramaCal';
import HojadeVidaEquipo from './components/Dashboard/DashboardAsistencial/HojadeVidaEquipo';
import SolicitudesComprasSinEnviar from './components/Dashboard/DashboardAsistencial/SolicitudesComprasSinEnviar';
import Solicitud from './components/Dashboard/DashboardAsistencial/Solicitud';
import SolicitudesEnviadas from './components/Dashboard/DashboardAsistencial/SolicitudesEnviadas';
import SolicitudesAprobadas from './components/Dashboard/DashboardAsistencial/SolicitudesAprobadas';
import SolicitudesRechazadas from './components/Dashboard/DashboardAsistencial/SolicitudesRechazadas';
import SolicitudDetail from './components/Dashboard/DashboardAsistencial/SolicitudDetail';
import SolicitudesClienteSinEnviar from './components/Dashboard/ClientePerfil/SolicitudesClienteSinEnviar';
import SolicitudesClienteEnviadas from './components/Dashboard/ClientePerfil/SolicitudesClienteEnviadas';
import SolicitudesClienteAprobadas from './components/Dashboard/ClientePerfil/SolicitudesClienteAprobadas';
import SolicitudesClienteRechazadas from './components/Dashboard/ClientePerfil/SolicitudesClienteRechazadas';
import SolicitudDetailCliente from './components/Dashboard/ClientePerfil/SolicitudDetailCliente';
import CompletarSolicitudCliente from './components/Dashboard/ClientePerfil/CompletarSolicitudCliente';
import ComprasSolicitudes from './components/Dashboard/DashboardCompras/ComprasSolicitudes';
import ComprasSolicitudesEnviadas from './components/Dashboard/DashboardCompras/ComprasSolicitudesEnviadas';
import SolicitudCompraEvaluar from './components/Dashboard/DashboardCompras/SolicitudCompraEvaluar';
import ComprasSolicitudesAprobadas from './components/Dashboard/DashboardCompras/ComprasSolicitudesAprobadas';
import ComprasSolicitudesRechazadas from './components/Dashboard/DashboardCompras/ComprasSolicitudesRechazadas';
import ComprasSolicitudDetail from './components/Dashboard/DashboardCompras/ComprasSolicitudDetail';
import BlogPost from './components/Dashboard/Noticias/BlogPost';
import BlogCategories from './components/Dashboard/Noticias/BlogCategories';
import InventarioPDF from './components/Dashboard/Equipos/InventarioPDF';
import CronogramaMtoPDF from './components/Dashboard/Equipos/CronogramaMtoPDF';
import CronogramaCalPDF from './components/Dashboard/Equipos/CronogramaCalPDF';
import HojaDeVidaPDF from './components/Dashboard/Equipos/HojaDeVidaPDF';
import ReporteMtoPDF from './components/Dashboard/Equipos/ReporteMtoPDF';
import SolicitudesMtoLista from './components/Dashboard/Equipos/SolicitudesMtoLista';
import InventarioPerfil from './components/Dashboard/Equipos/InventarioPerfil';
import CronogramaMtoPerfil from './components/Dashboard/Equipos/CronogramaMtoPerfil';
import CronogramaCalPerfil from './components/Dashboard/Equipos/CronogramaCalPerfil';
import IndicadoresPerfil from './components/Dashboard/Equipos/IndicadoresPerfil';
import ComprasPerfil from './components/Dashboard/ClientePerfil/ComprasPerfil';
import SubirReportesCal from './components/Dashboard/Equipos/SubirReportesCal';
import InventarioPDFAsis from './components/Dashboard/DashboardAsistencial/InventarioPDFAsis';
import CronogramaMtoPDFAsis from './components/Dashboard/DashboardAsistencial/CronogramaMtoPDFAsis';
import CronogramaCalPDFAsis from './components/Dashboard/DashboardAsistencial/CronogramaCalPDFAsis';
import HojadeVidaPDFAsis, { HojaDeVidaPDFAsis } from './components/Dashboard/DashboardAsistencial/HojadeVidaPDFAsis';
import ReporteMtoPDFAsis from './components/Dashboard/DashboardAsistencial/ReporteMtoPDFAsis';
import ProgramacionMensual from './components/Dashboard/Equipos/ProgramacionMensual';
import ProgramacionConsulta from './components/Dashboard/Equipos/ProgramacionConsulta';
import ProgramacionConsultaCal from './components/Dashboard/Equipos/ProgramacionConsultaCal';
import ProgramacionMtoPDF from './components/Dashboard/Equipos/ProgramacionMtoPDF';
import ProgramacionCalPDF from './components/Dashboard/Equipos/ProgramacionCalPDF';
import DocumentacionLegal from './components/Dashboard/Equipos/DocumentacionLegal';
import AsisDocLegal from './components/Dashboard/DashboardAsistencial/AsisDocLegal';
import SolicitudCorrectivoLista from './components/Dashboard/DashboardAsistencial/SolicitudCorrectivoLista';
import ClientesTecnico from './components/Dashboard/DashboardTecnico/Clientes/ClientesTecnico';
import ClientePerfilTec from './components/Dashboard/DashboardTecnico/ClientePerfilTec/ClientePerfilTec';
import IndPerfilTec from './components/Dashboard/DashboardTecnico/IndicadoresPerfil/IndPerfilTec';
import InventarioPerfilTec from './components/Dashboard/DashboardTecnico/InventarioTec/InventarioPerfilTec';
import CronoMtoPerfilTec from './components/Dashboard/DashboardTecnico/CronoMto/CronoMtoPerfilTec';
import EquipoPerfilTec from './components/Dashboard/DashboardTecnico/Equipos/EquipoPerfilTec';
import ReporteCorrectivoPDF from './components/Dashboard/Equipos/ReporteCorrectivoPDF';
import NuevoEquipoIA from './components/Dashboard/Equipos/NuevoEquipoIA';
import PlanesTab from './components/Planes/PlanesTab';
import AdminUsers from './components/Dashboard/Clientes/AdminUsers';
import MisRepuestos from './components/Dashboard/Equipos/MisRepuestos';
import ListaRepuestosPDF from './components/Dashboard/Equipos/ListaRepuestosPDF';
import Infraestructura from './components/Dashboard/Infraestructura/Infraestructura';
import PerfilArea from './components/Dashboard/Infraestructura/PerfilArea';
import MtoInfraPDF from './components/Dashboard/Infraestructura/MtoInfraPDF';
import MtoCorrPDF from './components/Dashboard/Infraestructura/MtoCorrPDF';
import PerfilInfraAsistencial from './components/Dashboard/DashboardAsistencial/PerfilInfraAsistencial';
import PerfilAreaAsistencial from './components/Dashboard/DashboardAsistencial/PerfilAreaAsistencial';
import RegistrosTecno from './components/Dashboard/Tecnovigilancia/registrosTecno';
import CasoTecnoVPDF from './components/Dashboard/Tecnovigilancia/CasoTecnoVPDF';
import PlanMtoPerfil from './components/Dashboard/Infraestructura/PlanMtoPerfil';
import PlanMtoPDF from './components/Dashboard/Infraestructura/PlanMtoPDF';
import SolicitudesInfraPerfil from './components/Dashboard/Infraestructura/SolicitudesInfraPerfil';
import TareasPerfil from './components/Dashboard/Tareas/TareasPerfil';
import ObsolUsuario from './components/Dashboard/Obsolescencia/ObsolUsuario';
import MobiliaroHome from './components/Dashboard/Mobiliario/MobiliaroHome';
import PerfilMobiliaro from './components/Dashboard/Mobiliario/PerfilMobiliaro';
import MobiliarioPDF from './components/Dashboard/Mobiliario/MobiliarioPDF';
import SolicitarInfo from './components/SolicitarInformacion/SolicitarInfo';
import SolicitarReunion from './components/SolicitarReunion/SolicitarReunion';
import ListadoPrecios from './components/ListadoPrecios/ListadoPrecios';
import QuienesSomos from './components/QuienesSomos/QuienesSomos';
import { apiV1, apiV2 } from './utils/api';
import HojadeVidaPDFTec from './components/Dashboard/DashboardTecnico/Equipos/HojadeVidaPDFTec';

// Comprueba el token del usuario para saber si esta logeado o no 
axios.defaults.baseURL='https://us-central1-alphaplatform.cloudfunctions.net/api';

const token = localStorage.FBIdToken;
if (token) {
  const decodedToken = jwtDecode(token);
  if (decodedToken.exp * 1000 < Date.now()) {
    store.dispatch(logoutUser());
    window.location.href = '/login';
  } else {
    store.dispatch({ type: SET_AUTHENTICATED });
    // Configurar el token en ambas instancias de API
    apiV1.defaults.headers.common['Authorization'] = token;
    apiV2.defaults.headers.common['Authorization'] = token;
    store.dispatch(getUserData());
  }
}


function App() {
  return (

    <Provider store={store}>

  
    <Router>
    
      <Switch>   
      <AppRoute exact path="/" component={Home} layout={Navbar} />   
      
      <Route exact path="/login" component={Login}></Route>
      <Route exact path="/admin/signup/4228" component={Register}></Route>
      <Route exact path="/planes" component={PlanesTab} layout={Navbar}></Route>
      
      <AppRoute  exact path="/solicitarinformacion" component={SolicitarInfo} layout={Navbar}></AppRoute>
      
      <AppRoute  exact path="/solicitarreunion" component={SolicitarReunion} layout={Navbar}></AppRoute>
      <AppRoute  exact path="/precios" component={ListadoPrecios} layout={Navbar}></AppRoute>
      <AppRoute  exact path="/quienessomos" component={QuienesSomos} layout={Navbar}></AppRoute>

      <RouteWithLayout  exact path="/dashboard" component={Dashboard} layout={LayoutD}/>
      <RouteWithLayout exact path="/dashboard/clientes" component={Clientes} layout={LayoutD}/>
      <RouteWithLayout exact path="/dashboard/clientes/adminusers/:clienteId" component={AdminUsers} layout={LayoutD}/>
      
      <RouteWithLayout exact path="/dashboard/marketplace" component={MarketplaceClientes} layout={LayoutD}/>      
      <RouteWithLayout exact path="/dashboard/homemarket" component={HomeMarket} layout={LayoutD}/>
      <RouteWithLayout exact path="/dashboard/homemarketempresa" component={HomeMarketEmpresa} layout={LayoutD}/>
      <RouteWithLayout exact path="/dashboard/categorias/:categoria" component={Categorias} layout={LayoutD}/>
      <RouteWithLayout exact path="/dashboard/cursos" component={Cursos} layout={LayoutD}/>
      <RouteWithLayout exact path="/dashboard/misrepuestos" component={MisRepuestos} layout={LayoutD}/>
      <RouteWithLayout exact path="/dashboard/miperfil" component={MiPerfil} layout={LayoutD}/>
      <RouteWithLayout exact path="/dashboard/perfilcliente/:clienteId" component={ClientePerfil} layout={LayoutD}/>
      <RouteWithLayout exact path="/dashboard/perfilcliente/inventario/:clienteId" component={InventarioPDF} layout={LayoutD}/>
      <RouteWithLayout exact path="/dashboard/perfilcliente/cronograma/:clienteId" component={CronogramaMtoPDF} layout={LayoutD}/>
      <RouteWithLayout exact path="/dashboard/perfilcliente/cronogramacal/:clienteId" component={CronogramaCalPDF} layout={LayoutD}/>
      <RouteWithLayout exact path="/dashboard/perfilcliente/repuestospdf/:handleuser" component={ListaRepuestosPDF} layout={LayoutD}/>
      <RouteWithLayout exact path="/dashboard/perfilcliente/:clienteId/programacion" component={ProgramacionMensual} layout={LayoutD}/>
      <RouteWithLayout exact path="/dashboard/perfilcliente/:clienteId/programacion/mantenimiento/:mes/:year" component={ProgramacionConsulta} layout={LayoutD}/>
      <RouteWithLayout exact path="/dashboard/perfilcliente/:clienteId/programacion/mantenimiento/:mes/:year/pdf" component={ProgramacionMtoPDF} layout={LayoutD}/>
      
      <RouteWithLayout exact path="/dashboard/perfilcliente/:clienteId/programacion/calibracion/:mes/:year" component={ProgramacionConsultaCal} layout={LayoutD}/>
      <RouteWithLayout exact path="/dashboard/perfilcliente/:clienteId/programacion/calibracion/:mes/:year/pdf" component={ProgramacionCalPDF} layout={LayoutD}/>
      <RouteWithLayout exact path="/dashboard/perfilcliente/:clienteId/documentacionlegal" component={DocumentacionLegal} layout={LayoutD}/>

      <RouteWithLayout exact path="/dashboard/perfilcliente/:clienteId/solicitudesinenviar" component={SolicitudesClienteSinEnviar} layout={LayoutD}/>
      <RouteWithLayout exact path="/dashboard/perfilcliente/:clienteId/solicitudesenviadas" component={SolicitudesClienteEnviadas} layout={LayoutD}/>
      <RouteWithLayout exact path="/dashboard/perfilcliente/:clienteId/solicitudesaprobadas" component={SolicitudesClienteAprobadas} layout={LayoutD}/>
      <RouteWithLayout exact path="/dashboard/perfilcliente/:clienteId/solicitudesrechazadas" component={SolicitudesClienteRechazadas} layout={LayoutD}/>
      <RouteWithLayout exact path="/dashboard/perfilcliente/:clienteId/solicitud/:solicitudid" component={SolicitudDetailCliente} layout={LayoutD}/>
      <RouteWithLayout exact path="/dashboard/perfilcliente/:clienteId/completarsolicitud/:solicitudid" component={CompletarSolicitudCliente} layout={LayoutD}/>
      <RouteWithLayout exact path="/dashboard/perfilcliente/equipo/:clienteId/:equipoId" component={PerfilEquipo} layout={LayoutD}/>
      <RouteWithLayout exact path="/dashboard/perfilcliente/:clienteId/inventarioperfil" component={InventarioPerfil} layout={LayoutD}/>
      <RouteWithLayout exact path="/dashboard/perfilcliente/nuevoequipoia/:clienteId" component={NuevoEquipoIA} layout={LayoutD}/>
      <RouteWithLayout exact path="/dashboard/perfilcliente/:clienteId/cronomtoperfil" component={CronogramaMtoPerfil} layout={LayoutD}/>
      <RouteWithLayout exact path="/dashboard/perfilcliente/:clienteId/cronocalperfil" component={CronogramaCalPerfil} layout={LayoutD}/>
      <RouteWithLayout exact path="/dashboard/perfilcliente/:clienteId/cronocalperfil/uploadcal" component={SubirReportesCal} layout={LayoutD}/>
      <RouteWithLayout exact path="/dashboard/perfilcliente/:clienteId/indicadoresperfil" component={IndicadoresPerfil} layout={LayoutD}/>
      
      <RouteWithLayout exact path="/dashboard/perfilcliente/:clienteId/comprasperfil" component={ComprasPerfil} layout={LayoutD}/>
{//Mobiliario e Industriales /dashboard/perfilcliente/${idcliente}/mobiliario
}
<RouteWithLayout exact path="/dashboard/perfilcliente/:clienteId/mobiliario" component={MobiliaroHome} layout={LayoutD}/>
<RouteWithLayout exact path="/dashboard/perfilcliente/mobiliario/:clienteId/:mobiliarioId" component={PerfilMobiliaro} layout={LayoutD}/>
<RouteWithLayout exact path="/dashboard/perfilcliente/inventariopdfmobiliario/:clienteId" component={MobiliarioPDF} layout={LayoutD}/>
{//Infraestructura
}
      <RouteWithLayout exact path="/dashboard/perfilcliente/:clienteId/infraestructura" component={Infraestructura} layout={LayoutD}/>
      <RouteWithLayout exact path="/dashboard/perfilcliente/:clienteId/infraestructura/:areaId" component={PerfilArea} layout={LayoutD}/>
      <RouteWithLayout exact path="/dashboard/perfilcliente/:clienteId/infraestructura/:areaId/reportepdfmto/:mtoId" component={MtoInfraPDF} layout={LayoutD}/>
      <RouteWithLayout exact path="/dashboard/perfilcliente/:clienteId/infraestructura/:areaId/reportepdfmtocorr/:mtoId" component={MtoCorrPDF} layout={LayoutD}/>
      <RouteWithLayout exact path="/dashboard/perfilcliente/:clienteId/planmtoinfra" component={PlanMtoPerfil} layout={LayoutD}/>
      <RouteWithLayout exact path="/dashboard/perfilcliente/:clienteId/planmtoinfra/pdf" component={PlanMtoPDF} layout={LayoutD}/>
      
      <RouteWithLayout exact path="/dashboard/perfilcliente/:clienteId/solicitudesmtoinfra" component={SolicitudesInfraPerfil} layout={LayoutD}/>
     

      <RouteWithLayout exact path="/dashboard/perfilcliente/:clienteId/tareas" component={TareasPerfil} layout={LayoutD}/>
      

{
      //dashboard/perfilcliente/vRXa6GZHJDIKjw6mFKAS
      }
       <RouteWithLayout exact path="/dashboard/perfilcliente/:clienteId/solicitudesmto" component={SolicitudesMtoLista} layout={LayoutD}/>
      <RouteWithLayout exact path="/dashboard/perfilcliente/equipo/hojadevidapdf/:clienteId/:equipoId" component={HojaDeVidaPDF} layout={LayoutD}/>
      
      <RouteWithLayout exact path="/dashboard/perfilcliente/equipo/hojadevidapdf/reportepdfmto/:clienteId/:equipoId/:mtoid" component={ReporteMtoPDF} layout={LayoutD}/>
      <RouteWithLayout exact path="/dashboard/perfilcliente/equipo/hojadevidapdf/reportepdfcorr/:clienteId/:equipoId/:mtoid" component={ReporteCorrectivoPDF} layout={LayoutD}/>
     
       <RouteWithLayout exact path="/dashboard/perfilcliente/equipo/:clienteId/:equipoId" component={PerfilEquipo} layout={LayoutD}/>
        <RouteWithLayout exact path="/homemarket/miscotizaciones" component={MisCotizaciones} layout={LayoutD}></RouteWithLayout>
        <RouteWithLayout exact path="/homemarket/cotizacionesaprobadas" component={CotizacionesAprobadas} layout={LayoutD}></RouteWithLayout>
        <RouteWithLayout exact path="/homemarket/cotizacionesrechazadas" component={CotizacionesRechazadas} layout={LayoutD}></RouteWithLayout>
        <RouteWithLayout exact path="/homemarket/cotizacionespublicadas" component={CotizacionesPublicadas} layout={LayoutD}></RouteWithLayout>
        <RouteWithLayout exact path="/homemarket/miscotizaciones/itemscotizacion/:cotizacionId" component={ItemsCotizacion} layout={LayoutD}></RouteWithLayout>
        <RouteWithLayout exact path="/homemarket/ofertascliente" component={OfertasCliente} layout={LayoutD}></RouteWithLayout>
        <RouteWithLayout exact path="/homemarket/ofertascliente/ofertadetails/:ofertaid" component={OfertaDetails} layout={LayoutD}></RouteWithLayout>
      
      <RouteWithLayout exact path="/dashboard/noticias" component={Noticias} layout={LayoutD}></RouteWithLayout>
      <RouteWithLayout exact path="/dashboard/noticias/:title" component={BlogPost} layout={LayoutD}></RouteWithLayout>
      <RouteWithLayout exact path="/dashboard/categorias/:categorie" component={BlogCategories} layout={LayoutD}></RouteWithLayout>
      


      <RouteWithLayout exact path="/homemarketempresa/categoriasmarket" component={CategoriasMarket} layout={LayoutD}></RouteWithLayout>
       <RouteWithLayout exact path="/homemarketempresa/categoriasmarket/cotipublicadas/:categoria" component={CotiPublicadas} layout={LayoutD}></RouteWithLayout>
       <RouteWithLayout exact path="/homemarketempresa/categoriasmarket/cotipublicadas/:categoria/respuesta/:cotizacionId" component={RespuestaCotizacion} layout={LayoutD}></RouteWithLayout>
       <RouteWithLayout exact path="/homemarketempresa/ofertasenviadas" component={OfertasEnviadas} layout={LayoutD}></RouteWithLayout>
       <RouteWithLayout exact path="/homemarketempresa/ofertasaprobadas" component={OfertasAprobadas} layout={LayoutD}></RouteWithLayout>
       <RouteWithLayout exact path="/homemarketempresa/ofertasrechazadas" component={OfertasRechazadas} layout={LayoutD}></RouteWithLayout>
     
       
       <RouteWithLayout exact path="/dashboard/equiposasistencial" component={EquiposAsistencial} layout={LayoutD}></RouteWithLayout>
       <RouteWithLayout exact path="/dashboard/solicitudesasistencial" component={SolicitudesAsistencial} layout={LayoutD}></RouteWithLayout>
      <RouteWithLayout exact path="/dashboard/inventarioequiposasistencial" component={InventarioEquipos} layout={LayoutD}></RouteWithLayout>
       <RouteWithLayout exact path="/dashboard/inventarioequiposasistencial/:clienteId" component={InventarioPDFAsis} layout={LayoutD}></RouteWithLayout>
      <RouteWithLayout exact path="/dashboard/inventarioequiposasistencial/hojadevidaequipo/:equipoId/:clienteId" component={HojadeVidaEquipo} layout={LayoutD}></RouteWithLayout>
       <RouteWithLayout exact path="/dashboard/cronogramamtoasistencial" component={CronogramaMto} layout={LayoutD}></RouteWithLayout>
       <RouteWithLayout exact path="/dashboard/cronogramamtoasistencial/:clienteId" component={CronogramaMtoPDFAsis} layout={LayoutD}></RouteWithLayout>
      <RouteWithLayout exact path="/dashboard/cronogramacalasistencial" component={CronogramaCal} layout={LayoutD}></RouteWithLayout>
      <RouteWithLayout exact path="/dashboard/cronogramacalasistencial/:clienteId" component={CronogramaCalPDFAsis} layout={LayoutD}></RouteWithLayout>
      <RouteWithLayout exact path="/dashboard/inventarioequiposasistencial/hojadevidapdf/:clienteId/:equipoId" component={HojadeVidaPDFAsis} layout={LayoutD}/>
      <RouteWithLayout exact path="/dashboard/inventarioequiposasistencial/hojadevidaequipo/reportepdfmto/:clienteId/:equipoId/:mtoid" component={ReporteMtoPDFAsis} layout={LayoutD}/>
      <RouteWithLayout exact path="/dashboard/equiposasistencial/:clienteId/documentacionlegal" component={AsisDocLegal} layout={LayoutD}/>
      <RouteWithLayout exact path="/dashboard/equiposasistencial/:clienteId/solicitudesmto" component={SolicitudCorrectivoLista} layout={LayoutD}/>
      
      <RouteWithLayout exact path="/dashboard/infrasasistencial" component={PerfilInfraAsistencial} layout={LayoutD}></RouteWithLayout>
      <RouteWithLayout exact path="/dashboard/infrasasistencial/:clienteId/infraestructura/:areaId" component={PerfilAreaAsistencial} layout={LayoutD}/>
      
      <RouteWithLayout exact path="/dashboard/solicitudesasistencial/missolicitudessinenviar" component={SolicitudesComprasSinEnviar} layout={LayoutD}></RouteWithLayout>
      <RouteWithLayout exact path="/dashboard/solicitudesasistencial/missolicitudessinenviar/solicitud/:solicitudid" component={Solicitud} layout={LayoutD} ></RouteWithLayout>
      <RouteWithLayout exact path="/dashboard/solicitudesasistencial/missolicitudesenviadas" component={SolicitudesEnviadas} layout={LayoutD}></RouteWithLayout>
      <RouteWithLayout exact path="/dashboard/solicitudesasistencial/missolicitudesaprobadas" component={SolicitudesAprobadas} layout={LayoutD}></RouteWithLayout>
      <RouteWithLayout exact path="/dashboard/solicitudesasistencial/missolicitudesrechazadas" component={SolicitudesRechazadas} layout={LayoutD}></RouteWithLayout>
      <RouteWithLayout exact path="/dashboard/solicitudesasistencial/solicitud/:solicitudid" component={SolicitudDetail} layout={LayoutD} ></RouteWithLayout>
      

      <RouteWithLayout exact path="/dashboard/compras/solicitudes" component={ComprasSolicitudes} layout={LayoutD} ></RouteWithLayout>
      <RouteWithLayout exact path="/dashboard/compras/solicitudes/solicitudesenviadas" component={ComprasSolicitudesEnviadas} layout={LayoutD} ></RouteWithLayout>
      <RouteWithLayout exact path="/dashboard/compras/solicitudes/solicitudesenviadas/solicitudevaluar/:solicitudid" component={SolicitudCompraEvaluar} layout={LayoutD} ></RouteWithLayout>
      <RouteWithLayout exact path="/dashboard/compras/solicitudes/solicitudesaprobadas" component={ComprasSolicitudesAprobadas} layout={LayoutD} ></RouteWithLayout>
      <RouteWithLayout exact path="/dashboard/compras/solicitudes/solicitudesrechazadas" component={ComprasSolicitudesRechazadas} layout={LayoutD} ></RouteWithLayout>
      <RouteWithLayout exact path="/dashboard/compras/solicitudes/solicitud/:solicitudid" component={ComprasSolicitudDetail} layout={LayoutD} ></RouteWithLayout>
      
     
       <Route exact path="/crearperfil" component={CrearPerfil}></Route>


      
      <RouteWithLayout exact path="/dashboard/perfilclientetec/:clienteId" component={ClientePerfilTec} layout={LayoutD}/>
     
       <RouteWithLayout exact path="/dashboard/clientes/tecnico" component={ClientesTecnico} layout={LayoutD}/>
      
       <RouteWithLayout exact path="/dashboard/perfilclientetec/:clienteId/indicadoresperfil" component={IndPerfilTec} layout={LayoutD}/>
       <RouteWithLayout exact path="/dashboard/perfilclientetec/:clienteId/cronomtoperfil" component={CronoMtoPerfilTec} layout={LayoutD}/>
       <RouteWithLayout exact path="/dashboard/perfilclientetec/:clienteId/inventarioperfil" component={InventarioPerfilTec} layout={LayoutD}/>
       <RouteWithLayout exact path="/dashboard/perfilclientetec/equipo/:clienteId/:equipoId" component={EquipoPerfilTec} layout={LayoutD}/>
       <RouteWithLayout exact path="/dashboard/perfilclientetec/equipo/hojadevidapdf/:clienteId/:equipoId" component={HojadeVidaPDFTec} layout={LayoutD}/>
      

       <RouteWithLayout exact path="/dashboard/perfilcliente/:clienteId/registrosTecno" component={RegistrosTecno} layout={LayoutD} />
      <RouteWithLayout exact path="/dashboard/casotecnovigilanciapdf/:clienteId/:docId/:equipoId" component={CasoTecnoVPDF} layout={LayoutD} />


      <RouteWithLayout exact path="/dashboard/obsolescenciaUsuario" component={ObsolUsuario} layout={LayoutD} />
       </Switch>
      
    
    </Router>

    </Provider>
  );
}

// <Route path='/'exact component={Home} />
export default App;
