import React, { Component } from 'react';
import './TablaTecno.css';

import { deleteCasoTecno, getCasosTecnoEquipo, editCasoTecno, clearErrors } from '../../../redux/actions/dataactions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import MaterialTable from 'material-table';




import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CircularProgress from '@material-ui/core/CircularProgress';
import DescriptionIcon from '@material-ui/icons/Description';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Accordion, AccordionDetails, AccordionSummary, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import MyButton from '../../../utils/MyButton';
import { FaArrowLeft, FaCheck, FaEdit, FaPlus, FaTrashAlt } from 'react-icons/fa';
import moment from 'moment';

export class TablaTecno extends Component {

  constructor() {
    super();
    this.state = {
      testData: {},

      docId: "",

      // Información del paciente
      tipoIdentificacion: "",
      numeroIdentificacion: "",
      sexo: "",
      edad: "",
      edadEn: "",
      eps: "",
      diagnosticoInicialPaciente: "",

      // Información del equipo
      nombreGenericoDispositivo: "",
      nombreComercialDispositivo: "",
      RegistroSanitario_PermisoComercializacion: "",
      activoFijo: "", //Alpha ?
      lote: "",
      modelo: "",
      referencia: "",
      serie: "",
      marca: "",
      importadorDistribuidor: "",
      areaAccidente: "",
      servicio: "",
      ubicacion: "",
      masDeUnUso: "",

      // Descripcion del evento o incidente adverso
      fechaDelEvento: "",
      fechaReporte: "",
      momentoDeteccion: "",
      clasificacionCaso: "",
      descripcionCaso: "",
      desenlaceCaso: "",
      otro: "",

      // Gestión realizada
      causaCaso: "",
      accionTomada: "",
      reporteDistibuidor: "",
      fechaReporteDistribuidor: "",
      envioDistribuidor: "",
      fechaEnvioDistribuidor: "",
      disponibleEvaluacion: "",

      // Informacion del reportante
      nombreReportante: "",
      profesion: "",
      servicioPerteneciente: "",
      correoInstitucional: "",
      estadoReporte: "",

      errors: {},
      aprobado: {},
      openDetails: false,
      openEdit: false,
      openDelete: false,
    };
  };

  componentDidMount() {
    this.props.getCasosTecnoEquipo(this.props.equipoId);
  }

  handleOpenEdit = (data) => {
    this.setState({ openEdit: true, docId: data.id, errors: {}, aprobado: {} });
    const partes = data.areaAccidente.split('/')
    this.setState({

      tipoIdentificacion: data.tipoIdentificacion,
      numeroIdentificacion: data.numeroIdentificacion,
      sexo: data.sexo,
      edad: data.edad,
      edadEn: data.edadEn,
      eps: data.eps,
      diagnosticoInicialPaciente: data.diagnosticoInicialPaciente,

      nombreGenericoDispositivo: data.nombreGenericoDispositivo,
      nombreComercialDispositivo: data.nombreComercialDispositivo,
      RegistroSanitario_PermisoComercializacion: data.RegistroSanitario_PermisoComercializacion,
      activoFijo: data.activoFijo, //Alpha ?
      lote: data.lote,
      modelo: data.modelo,
      referencia: data.referencia,
      serie: data.serie,
      marca: data.marca,
      importadorDistribuidor: data.importadorDistribuidor,
      servicio: partes[0],
      ubicacion: partes[1],
      masDeUnUso: data.masDeUnUso,

      // Descripcion del evento o incidente adverso
      fechaDelEvento: moment(data.fechaDelEvento, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      fechaReporte: moment(data.fechaReporte, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      momentoDeteccion: data.momentoDeteccion,
      clasificacionCaso: data.clasificacionCaso,
      descripcionCaso: data.descripcionCaso,
      desenlaceCaso: data.desenlaceCaso,
      otro: data.otro,

      causaCaso: data.causaCaso,
      accionTomada: data.accionTomada,
      reporteDistibuidor: data.reporteDistibuidor,
      fechaReporteDistribuidor: moment(data.fechaReporteDistribuidor, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      envioDistribuidor: data.envioDistribuidor,
      fechaEnvioDistribuidor: moment(data.fechaEnvioDistribuidor, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      disponibleEvaluacion: data.disponibleEvaluacion,

      //Informacion del reportante

      nombreReportante: this.props.usuario.nombre,
      profesion: this.props.usuario.tipouser,
      servicioPerteneciente: "",
      direccion: this.props.usuario.direccion,
      telefono: this.props.usuario.telefono,
      correoInstitucional: this.props.usuario.email,
      estadoReporte: data.estadoReporte
    });
  };

  handleEdit = () => {
    const reporte = {

      tipoIdentificacion: this.state.tipoIdentificacion,
      numeroIdentificacion: this.state.numeroIdentificacion,
      sexo: this.state.sexo,
      edad: this.state.edad,
      edadEn: this.state.edadEn,
      eps: this.state.eps,
      diagnosticoInicialPaciente: this.state.diagnosticoInicialPaciente,

      nombreComercialDispositivo: this.state.nombreComercialDispositivo,
      lote: this.state.lote,
      referencia: this.state.referencia,
      areaAccidente: this.state.servicio + "/" + this.state.ubicacion,
      masDeUnUso: this.state.masDeUnUso,

      // Descripcion del evento o incidente adverso
      fechaDelEvento: moment(this.state.fechaDelEvento).format('DD/MM/YYYY'),
      desenlaceCaso: this.state.desenlaceCaso,
      otro: this.state.otro,
      descripcionCaso: this.state.descripcionCaso,

      causaCaso: this.state.causaCaso,
      accionTomada: this.state.accionTomada,
      reporteDistibuidor: this.state.reporteDistibuidor,
      fechaReporteDistribuidor: moment(this.state.fechaReporteDistribuidor).format('DD/MM/YYYY'),
      envioDistribuidor: this.state.envioDistribuidor,
      fechaEnvioDistribuidor: moment(this.state.fechaEnvioDistribuidor).format('DD/MM/YYYY'),
      disponibleEvaluacion: this.state.disponibleEvaluacion,

      //Informacion del reportante
      nombreReportante: this.state.nombreReportante,
      profesion: this.state.profesion,
      direccion: this.state.direccion,
      telefono: this.state.telefono,
      servicioPerteneciente: this.state.servicioPerteneciente,
      correoInstitucional: this.state.correoInstitucional,
      estadoReporte: this.state.estadoReporte
    }
    console.log("Función editar caso")
    console.log(this.state.docId)
    this.props.editCasoTecno(reporte, this.state.docId)
  }

  handleCloseEdit = () => {
    this.props.clearErrors();
    this.setState({ openEdit: false, errors: {}, docId: "", aprobado: {} });
    this.setState({

      // Información del paciente
      tipoIdentificacion: "",
      numeroIdentificacion: "",
      sexo: "",
      edad: "",
      edadEn: "",
      eps: "",
      diagnosticoInicialPaciente: "",

      // Información del equipo
      nombreGenericoDispositivo: "",
      nombreComercialDispositivo: "",
      RegistroSanitario_PermisoComercializacion: "",
      activoFijo: "", //Alpha ?
      lote: "",
      modelo: "",
      referencia: "",
      serie: "",
      marca: "",
      importadorDistribuidor: "",
      areaAccidente: "",
      servicio: "",
      ubicacion: "",
      masDeUnUso: "",

      // Descripcion del evento o incidente adverso
      fechaDelEvento: "",
      fechaReporte: "",
      momentoDeteccion: "",
      clasificacionCaso: "",
      descripcionCaso: "",
      desenlaceCaso: "",
      otro: "",

      // Gestión realizada
      causaCaso: "",
      accionTomada: "",
      reporteDistibuidor: "",
      fechaReporteDistribuidor: "",
      envioDistribuidor: "",
      fechaEnvioDistribuidor: "",
      disponibleEvaluacion: "",

      // Informacion del reportante
      nombreReportante: "",
      profesion: "",
      servicioPerteneciente: "",
      correoInstitucional: "",
      estadoReporte: ""
    })
    this.state.aprobado.mensaje && (
      this.props.getCasosTecnoEquipo(this.props.equipoId)
    )
  };

  handleOpenDelete = (id) => {
    this.setState({ openDelete: true, docId: id, aprobado: {} });
  };

  handleDelete = () => {
    this.props.deleteCasoTecno(this.state.docId, this.props.equipoId)
  }

  handleCloseDelete = () => {
    this.setState({ openDelete: false, errors: {}, docId: "", aprobado: {} });
    this.state.aprobado.mensaje && (
      this.props.getCasosTecnoEquipo(this.props.equipoId)
    )
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  static getDerivedStateFromProps(props, state) {
    const newState = {};

    if (props.UI.errors) {
      newState.errors = props.UI.errors;
    }

    if (props.UI.aprobado) {
      newState.aprobado = props.UI.aprobado;
    }

    return Object.keys(newState).length > 0 ? newState : null;
  }

  render() {

    const { casostecnovigilancia, loadingtecno } = this.props.data;
    const { errors } = this.state;
    const { UI: { loading } } = this.props;


    const tableIcons = {
      Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
      Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
      Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
      DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
      Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
      Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
      Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
      FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
      LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
      NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
      PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
      ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
      SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
      ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
      ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
      Description: forwardRef((props, ref) => <DescriptionIcon {...props} ref={ref} />)
    };

    const columns = [
      { title: 'último reportante', field: 'nombreReportante' },
      { title: 'Clasificación', field: 'clasificacionCaso' },
      { title: 'Fecha del evento', field: 'fechaDelEvento' },
      { title: 'Fecha del reporte', field: 'fechaReporte' },
    ]

    let mapeoprogcals = !loadingtecno ? (
      <div>
        <MaterialTable
          columns={columns}
          data={casostecnovigilancia}
          title={
            <h2 style={{ fontSize: '16px', color: '#03178c', fontWeight: '600' }}>Casos de tecnovigilancia reportados</h2>
          }
          icons={tableIcons}
          options={{
            cellStyle: {
              fontSize: 12,
              fontWeight: 500,
              color: '#696B6D'
            },
            headerStyle: {
              fontSize: 14,
              fontWeight: 'bolder',
              color: "#03178c" // Ajusta el tamaño de fuente según tus necesidades
            }
          }}
          actions={[

            rowData => ({
              icon: tableIcons.Description,
              tooltip: 'Ver reporte en PDF',
              onClick: (event, rowData) => {
                window.location.href = `/dashboard/casotecnovigilanciapdf/${this.props.clienteId}/${rowData.id}/${rowData.equipoId}`
              }
            })
            ,

            {
              icon: tableIcons.Edit,
              tooltip: 'Editar caso',
              onClick: (event, rowData) => {

                this.handleOpenEdit(rowData);
              }
            },

            {
              icon: tableIcons.Delete,
              tooltip: 'Eliminar Caso',
              onClick: (event, rowData) => {

                this.handleOpenDelete(rowData.id);
              }
            }
          ]}
        >
        </MaterialTable>
      </div>
    ) : (<div className="div_cargando">
      <CircularProgress size={50} color="primary" className="login-progress" />
    </div>);

    return (
      <div className="listamtoprog-container">
        <div className="listamtoprog-table">
          {mapeoprogcals}
        </div>

        {
          // ver detelles reporte
        }
        <Dialog
          open={this.state.openDetails}
          onClose={this.handleCloseDetails}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Detalles del caso</DialogTitle>
        </Dialog>

        {
          // Editar reporte
        }

        <Dialog
          open={this.state.openEdit}
          onClose={this.handleCloseEdit}
          fullWidth
          maxWidth="sm"
        >
          {this.state.aprobado.mensaje ? (

            <div className='div-aprobado'>
              <div className='div-icono-aprobado'>
                <FaCheck color="#ffffff" className='icono-aprobado' />
              </div>
              <h2 className='text-aprobado'>¡Se ha registrado exitosamente el caso!</h2>

              {(this.state.clasificacionCaso == "Evento adverso serio" || this.state.clasificacionCaso == "Incidente adverso serio") &&
                <div className='mensaje_casos'>
                  <p>
                    Este caso está registrado como evento/incidente adverso serio. <br />
                    Recuerde que tiene menos de <strong>72 horas desde la ocurrencia del caso</strong> para completar y subir el {' '}
                    <a href="https://www.saludcapital.gov.co/SectorBelleza/Galeria%20de%20Descargas/Acreditaci%C3%B3n/Est%C3%A1ndares%20acreditaci%C3%B3n%20est%C3%A9tica/REPORTE%20EVENTOS%20SERIOS%20FOREIA001.pdf" target="_blank" rel="noopener noreferrer">
                      formato FOREIA001
                    </a>{' '}
                    a la plataforma del INVIMA.
                  </p>
                </div>
              }

            </div>
          ) : (
            <div>


              <DialogTitle>Editar caso de tecnovigilancia</DialogTitle>

              <DialogContent>
                <form>

                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <h4 className="text-title-accorion">Información del paciente (Opcional) </h4>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="flex-accordion">
                        <div className="input_divido">
                          <div className="input_divido_children">
                            <FormControl className="formcontrol-nuevocliente" error={errors.tipoIdentificacion ? true : false}>
                              <InputLabel>Tipo de documento</InputLabel>
                              <Select
                                required
                                name="tipoIdentificacion"
                                id="tipoIdentificacion"
                                value={this.state.tipoIdentificacion}
                                onChange={this.handleChange}
                                fullWidth
                              >
                                <MenuItem value="">
                                  <em></em>
                                </MenuItem>
                                <MenuItem value="CC">Cédula de Ciudadanía</MenuItem>
                                <MenuItem value="TI">Tarjeta de Identidad</MenuItem>
                                <MenuItem value="RC">Registro Civil</MenuItem>
                                <MenuItem value="PS">Pasaporte</MenuItem>
                                <MenuItem value="CE">Cédula de Extranjería</MenuItem>
                                <MenuItem value="MI">Menor sin Identificación</MenuItem>
                                <MenuItem value="AI">Adulto sin Identificación</MenuItem>
                                <MenuItem value="HC">Historia Clinica</MenuItem>

                              </Select>
                              <FormHelperText>{errors.tipoIdentificacion}</FormHelperText>
                            </FormControl>
                          </div>
                          <div className="input_divido_children">
                            <TextField className="login-input-email"
                              required
                              type="number"
                              name="numeroIdentificacion"
                              id="numeroIdentificacion"
                              label="Número de identificación"
                              value={this.state.numeroIdentificacion}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.numeroIdentificacion}
                              error={errors.numeroIdentificacion ? true : false}
                            />
                          </div>
                        </div>

                        <div className="input_divido">
                          <div className="input_divido_children">
                            <FormControl className="formcontrol-nuevocliente" error={errors.sexo ? true : false}>
                              <InputLabel>Sexo</InputLabel>
                              <Select
                                required
                                name="sexo"
                                id="sexo"
                                value={this.state.sexo}
                                onChange={this.handleChange}
                                fullWidth
                              >
                                <MenuItem value="">
                                  <em></em>
                                </MenuItem>
                                <MenuItem value="Femenino">Femenino</MenuItem>
                                <MenuItem value="Masculino">Masculino</MenuItem>
                                {//<MenuItem value="Sin dato">39 tipos de Gay</MenuItem>
                                }
                                <MenuItem value="Sin dato">Sin dato</MenuItem>

                              </Select>
                              <FormHelperText>{errors.sexo}</FormHelperText>
                            </FormControl>
                          </div>
                        </div>


                        <div className="input_divido">
                          <div className="input_divido_children">
                            <TextField className="login-input-email"
                              required
                              type="number"
                              name="edad"
                              id="edad"
                              label="Edad"
                              value={this.state.edad}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.edad}
                              error={errors.edad ? true : false}
                            />
                          </div>
                          <div className="input_divido_children">
                            <FormControl className="formcontrol-nuevocliente" error={errors.edadEn ? true : false}>
                              <InputLabel>Edad de la persona en</InputLabel>
                              <Select
                                required
                                name="edadEn"
                                id="edadEn"
                                value={this.state.edadEn}
                                onChange={this.handleChange}
                                fullWidth
                              >
                                <MenuItem value="">
                                  <em></em>
                                </MenuItem>
                                <MenuItem value="días">Días</MenuItem>
                                <MenuItem value="semanas">Semanas</MenuItem>
                                <MenuItem value="meses">Meses</MenuItem>
                                <MenuItem value="años">Años</MenuItem>
                              </Select>
                              <FormHelperText>{errors.edadEn}</FormHelperText>
                            </FormControl>
                          </div>
                        </div>

                        <TextField className="login-input-email"
                          required
                          type="text"
                          name="eps"
                          id="eps"
                          label="EPS del paciente"
                          value={this.state.eps}
                          onChange={this.handleChange}
                          fullWidth
                          helperText={errors.eps}
                          error={errors.eps ? true : false}
                        />

                        <TextField className="login-input-email"
                          required
                          type="text"
                          name="diagnosticoInicialPaciente"
                          id="diagnosticoInicialPaciente"
                          label="Diagnóstico inicial del paciente"
                          value={this.state.diagnosticoInicialPaciente}
                          onChange={this.handleChange}
                          fullWidth
                          helperText={errors.diagnosticoInicialPaciente}
                          error={errors.diagnosticoInicialPaciente ? true : false}
                        />
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <h4 className="text-title-accorion">Información del equipo</h4>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="flex-accordion">

                        <TextField className="login-input-email"
                          required
                          type="text"
                          name="nombreGenericoDispositivo"
                          id="nombreGenericoDispositivo"
                          label="Nombre genérico del equipo"
                          value={this.state.nombreGenericoDispositivo}
                          onChange={this.handleChange}
                          fullWidth
                          helperText={errors.nombreGenericoDispositivo}
                          error={errors.nombreGenericoDispositivo ? true : false}
                          disabled
                        />

                        <TextField className="login-input-email"
                          required
                          type="text"
                          name="nombreComercialDispositivo"
                          id="nombreComercialDispositivo"
                          label="Nombre comercial del equipo"
                          value={this.state.nombreComercialDispositivo}
                          onChange={this.handleChange}
                          fullWidth
                          helperText={errors.nombreComercialDispositivo}
                          error={errors.nombreComercialDispositivo ? true : false}

                        />


                        <div className="input_divido">
                          <div className="input_divido_children">
                            <TextField className="login-input-email"
                              required
                              type="text"
                              name="RegistroSanitario_PermisoComercializacion"
                              id="RegistroSanitario_PermisoComercializacion"
                              label="Registro Invima"
                              value={this.state.RegistroSanitario_PermisoComercializacion}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.RegistroSanitario_PermisoComercializacion}
                              error={errors.RegistroSanitario_PermisoComercializacion ? true : false}
                              disabled
                            />
                          </div>
                          <div className="input_divido_children">
                            <TextField className="login-input-email"
                              required
                              type="text"
                              name="activoFijo"
                              id="activoFijo"
                              label="Activo fijo del equipo"
                              value={this.state.activoFijo}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.activoFijo}
                              error={errors.activoFijo ? true : false}
                              disabled
                            />
                          </div>
                        </div>

                        <div className="input_divido">
                          <div className="input_divido_children">
                            <TextField className="login-input-email"

                              type="text"
                              name="lote"
                              id="lote"
                              label="Lote"
                              value={this.state.lote}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.lote}
                              error={errors.lote ? true : false}
                            />
                          </div>
                          <div className="input_divido_children">
                            <TextField className="login-input-email"
                              required
                              type="text"
                              name="modelo"
                              id="modelo"
                              label="Modelo"
                              value={this.state.modelo}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.modelo}
                              error={errors.modelo ? true : false}
                              disabled
                            />
                          </div>
                        </div>


                        <div className="input_divido">
                          <div className="input_divido_children">
                            <TextField className="login-input-email"

                              type="text"
                              name="referencia"
                              id="referencia"
                              label="Referencia"
                              value={this.state.referencia}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.referencia}
                              error={errors.referencia ? true : false}
                            />
                          </div>
                          <div className="input_divido_children">
                            <TextField className="login-input-email"
                              required
                              type="text"
                              name="serie"
                              id="serie"
                              label="Serie"
                              value={this.state.serie}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.serie}
                              error={errors.serie ? true : false}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="input_divido">
                          <div className="input_divido_children">
                            <TextField className="login-input-email"
                              required
                              type="text"
                              name="marca"
                              id="marca"
                              label="Razón social del fabricante"
                              value={this.state.marca}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.marca}
                              error={errors.marca ? true : false}
                              disabled
                            />
                          </div>
                          <div className="input_divido_children">
                            <TextField className="login-input-email"
                              required
                              type="text"
                              name="importadorDistribuidor"
                              id="importadorDistribuidor"
                              label="Importador/distribuidor"
                              value={this.state.importadorDistribuidor}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.importadorDistribuidor}
                              error={errors.importadorDistribuidor ? true : false}
                              disabled
                            />
                          </div>
                        </div>

                        <div className="input_divido">
                          <div className="input_divido_children">
                            <TextField className="login-input-email"
                              required
                              type="text"
                              name="servicio"
                              id="servicio"
                              label="Servicio"
                              value={this.state.servicio}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.servicio}
                              error={errors.servicio ? true : false}
                            />
                          </div>
                          <div className="input_divido_children">
                            <TextField className="login-input-email"
                              required
                              type="text"
                              name="ubicacion"
                              id="ubicacion"
                              label="Ubicación"
                              value={this.state.ubicacion}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.ubicacion}
                              error={errors.ubicacion ? true : false}
                            />
                          </div>
                        </div>



                        <FormControl className="formcontrol-nuevocliente" error={errors.masDeUnUso ? true : false}>
                          <InputLabel>¿Se ha usado más de una vez?</InputLabel>
                          <Select
                            required
                            name="masDeUnUso"
                            id="masDeUnUso"
                            value={this.state.masDeUnUso}
                            onChange={this.handleChange}
                            fullWidth
                          >
                            <MenuItem value="">
                              <em></em>
                            </MenuItem>
                            <MenuItem value="Si">Sí</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                          <FormHelperText>{errors.masDeUnUso}</FormHelperText>
                        </FormControl>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <h4 className="text-title-accorion">Clasificación del caso</h4>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="flex-accordion">

                        <div className="input_divido">
                          <div className="input_divido_children">
                            <TextField className="login-input-email"
                              required
                              type="date"
                              name="fechaDelEvento"
                              id="fechaDelEvento"
                              label="Fecha de ocurrencia del evento"
                              value={this.state.fechaDelEvento}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.fechaDelEvento}
                              error={errors.fechaDelEvento ? true : false}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                max: moment().format('YYYY-MM-DD') // Establece la fecha máxima como la fecha actual
                              }}
                            />
                          </div>
                          <div className="input_divido_children">
                            <TextField className="login-input-email"
                              required
                              type="date"
                              name="fechaReporte"
                              id="fechaReporte"
                              label="Fecha del reporte"
                              value={this.state.fechaReporte}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.fechaReporte}
                              error={errors.fechaReporte ? true : false}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                max: moment().format('YYYY-MM-DD') // Establece la fecha máxima como la fecha actual
                              }}
                              disabled
                            />
                          </div>
                        </div>

                        <div className="input_divido">
                          <div className="input_divido_children">
                            <FormControl className="formcontrol-nuevocliente" error={errors.momentoDeteccion ? true : false}>
                              <InputLabel>Detección del caso</InputLabel>
                              <Select
                                required
                                name="momentoDeteccion"
                                id="momentoDeteccion"
                                value={this.state.momentoDeteccion}
                                onChange={this.handleChange}
                                fullWidth
                                disabled
                              >
                                <MenuItem value="">
                                  <em></em>
                                </MenuItem>
                                <MenuItem value="Antes del uso del DM">Antes de utilizarlo</MenuItem>
                                <MenuItem value="Durante el uso del DM">Durante el uso</MenuItem>
                                <MenuItem value="Durante el uso del DM">Después de utilizarlo</MenuItem>
                              </Select>

                              <FormHelperText>{errors.momentoDeteccion}</FormHelperText>
                            </FormControl>
                          </div>
                          <div className="input_divido_children">
                            <FormControl className="formcontrol-nuevocliente" error={errors.clasificacionCaso ? true : false}>
                              <InputLabel>Clasificación</InputLabel>
                              <Select
                                required
                                name="clasificacionCaso"
                                id="clasificacionCaso"
                                value={this.state.clasificacionCaso}
                                onChange={this.handleChange}
                                fullWidth
                                disabled
                              >
                                <MenuItem value="">
                                  <em></em>
                                </MenuItem>
                                <MenuItem value="Evento adverso serio">Evento adverso serio</MenuItem>
                                <MenuItem value="Evento adverso no serio">Evento adverso no serio</MenuItem>
                                <MenuItem value="Incidente adverso serio">Incidente adverso serio</MenuItem>
                                <MenuItem value="Incidente adverso no serio">Incidente adverso no serio</MenuItem>

                              </Select>
                              <FormHelperText>{errors.clasificacionCaso}</FormHelperText>
                            </FormControl>
                          </div>
                        </div>

                        <FormControl className="formcontrol-nuevocliente" error={errors.desenlaceCaso ? true : false}>
                          <InputLabel>Desenlace del caso</InputLabel>
                          <Select
                            required
                            name="desenlaceCaso"
                            id="desenlaceCaso"
                            value={this.state.desenlaceCaso}
                            onChange={this.handleChange}
                            fullWidth
                          >
                            <MenuItem value="">
                              <em></em>
                            </MenuItem>
                            <MenuItem value="Murio">Murio</MenuItem>
                            <MenuItem value="Daño de una función o estructura corporal">Daño de una función o estructura corporal</MenuItem>
                            <MenuItem value="Enfermedad o daño que amenace la vida">Enfermedad o daño que amenace la vida</MenuItem>
                            <MenuItem value="Requiere intervención médica o quirúrgica, para prevenir un daño permanente de una estructura o función corporal">Requiere intervención médica o quirúrgica, para prevenir un daño permanente de una estructura o función corporal</MenuItem>
                            <MenuItem value="Hospitalización inicial o prolongada">Hospitalización inicial o prolongada</MenuItem>
                            <MenuItem value="No hubo daño">No hubo daño</MenuItem>
                            <MenuItem value="Otro">Otro</MenuItem>
                          </Select>
                          <FormHelperText>{errors.desenlaceCaso}</FormHelperText>
                        </FormControl>

                        <TextField className="login-input-email"
                          required
                          type="text"
                          name="otro"
                          id="otro"
                          label="¿Otro? Especifique"
                          value={this.state.otro}
                          onChange={this.handleChange}
                          fullWidth
                          helperText={errors.otro}
                          error={errors.otro ? true : false}
                          disabled={this.state.desenlaceCaso == "Otro" ? false : true}
                        />
                        <TextField className="login-input-email"
                          required
                          type="text"
                          name="descripcionCaso"
                          id="descripcionCaso"
                          label="Descripción del caso"
                          value={this.state.descripcionCaso}
                          onChange={this.handleChange}
                          fullWidth
                          helperText={errors.descripcionCaso}
                          error={errors.descripcionCaso ? true : false}
                        />
                      </div>

                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <h4 className="text-title-accorion">Gestion Realizada</h4>
                    </AccordionSummary>

                    <AccordionDetails>
                      <div className="flex-accordion">
                        <FormControl className="formcontrol-nuevocliente" error={errors.causaCaso ? true : false}>
                          <InputLabel>Causa probable del caso</InputLabel>
                          <Select
                            required
                            name="causaCaso"
                            id="causaCaso"
                            value={this.state.causaCaso}
                            onChange={this.handleChange}
                            fullWidth
                          >
                            <MenuItem value="">
                              <em></em>
                            </MenuItem>
                            <MenuItem value="Uso anormal">Uso anormal</MenuItem>
                            <MenuItem value="Respuesta fisiológica anormal o inesperada">Respuesta fisiológica anormal o inesperada</MenuItem>
                            <MenuItem value="Falla en la alarma">Falla en la alarma</MenuItem>
                            <MenuItem value="Uso de material biológico">Uso de material biológico</MenuItem>
                            <MenuItem value="Calibración">Calibración</MenuItem>
                            <MenuItem value="Hardware del computador">Hardware del computador</MenuItem>
                            <MenuItem value="Contaminación durante la producción">Contaminación durante la producción</MenuItem>
                            <MenuItem value="Contaminación post-producción">Contaminación post-producción</MenuItem>
                            <MenuItem value="Diseño">Diseño</MenuItem>
                            <MenuItem value="Desconexión">Desconexión</MenuItem>
                            <MenuItem value="Componente eléctrico">Componente eléctrico</MenuItem>
                            <MenuItem value="Circuito eléctrico">Circuito eléctrico</MenuItem>
                            <MenuItem value="Contacto eléctrico">Contacto eléctrico</MenuItem>
                            <MenuItem value="Interferencia Electromagnética IEM">Interferencia Electromagnética IEM</MenuItem>
                            <MenuItem value="Fecha de expiración">Fecha de expiración</MenuItem>
                            <MenuItem value="Falso Negativo">Falso Negativo</MenuItem>
                            <MenuItem value="Falso positivo">Falso positivo</MenuItem>
                            <MenuItem value="Resultado falso de la prueba">Resultado falso de la prueba</MenuItem>
                            <MenuItem value="Falla en el dispositivo implantable">Falla en el dispositivo implantable</MenuItem>
                            <MenuItem value="Ambiente Inapropiado">Ambiente Inapropiado</MenuItem>
                            <MenuItem value="Incompatibilidad">Incompatibilidad</MenuItem>
                            <MenuItem value="Instrucciones para uso y etiquetado">Instrucciones para uso y etiquetado</MenuItem>
                            <MenuItem value="Escape/ sellado">Escape/ sellado</MenuItem>
                            <MenuItem value="Mantenimiento">Mantenimiento</MenuItem>
                            <MenuItem value="Fabricación">Fabricación</MenuItem>
                            <MenuItem value="Material">Material</MenuItem>
                            <MenuItem value="Componentes Mecánicos">Componentes Mecánicos</MenuItem>
                            <MenuItem value="Condiciones no higiénicas">Condiciones no higiénicas</MenuItem>
                            <MenuItem value="No relacionado con el dispositivo">No relacionado con el dispositivo</MenuItem>
                            <MenuItem value="Otros">Otros</MenuItem>
                            <MenuItem value="Empaque">Empaque</MenuItem>
                            <MenuItem value="Anatomía/Fisiología del paciente">Anatomía/Fisiología del paciente</MenuItem>
                            <MenuItem value="Condición del paciente">Condición del paciente</MenuItem>
                            <MenuItem value="Fuente de energía">Fuente de energía</MenuItem>
                            <MenuItem value="Medidas de protección">Medidas de protección</MenuItem>
                            <MenuItem value="Aseguramiento  de la calidad en la institución para la atención en salud">Aseguramiento  de la calidad en la institución para la atención en salud</MenuItem>
                            <MenuItem value="Radiación">Radiación</MenuItem>
                            <MenuItem value="Software">Software</MenuItem>
                            <MenuItem value="Esterilización/desinfección/ limpieza">Esterilización/desinfección/ limpieza</MenuItem>
                            <MenuItem value="Condiciones de almacenamiento">Condiciones de almacenamiento</MenuItem>
                            <MenuItem value="Alteración/falsificación/ sabotaje">Alteración/falsificación/ sabotaje</MenuItem>
                            <MenuItem value="Entrenamiento">Entrenamiento</MenuItem>
                            <MenuItem value="Transporte y entrega">Transporte y entrega</MenuItem>
                            <MenuItem value="Sin identificar">Sin identificar</MenuItem>
                            <MenuItem value="Capacidad de Uso">Capacidad de Uso</MenuItem>
                            <MenuItem value="Error de Uso">Error de Uso</MenuItem>
                            <MenuItem value="Desgaste">Desgaste</MenuItem>
                          </Select>
                          <FormHelperText>{errors.causaCaso}</FormHelperText>

                        </FormControl>

                        <TextField className="login-input-email"
                          required
                          type="text"
                          name="accionTomada"
                          id="accionTomada"
                          label="Acciones correctivas y preventivas iniciadas"
                          value={this.state.accionTomada}
                          onChange={this.handleChange}
                          fullWidth
                          helperText={errors.accionTomada}
                          error={errors.accionTomada ? true : false}
                        />

                        <div className="input_divido">
                          <div className="input_divido_children">
                            <FormControl className="formcontrol-nuevocliente" error={errors.reporteDistibuidor ? true : false}>
                              <InputLabel>¿Reportó al distribuidor?</InputLabel>
                              <Select
                                required
                                name="reporteDistibuidor"
                                id="reporteDistibuidor"
                                value={this.state.reporteDistibuidor}
                                onChange={this.handleChange}
                                fullWidth
                              >
                                <MenuItem value="">
                                  <em></em>
                                </MenuItem>
                                <MenuItem value="Si">Sí</MenuItem>
                                <MenuItem value="No">No</MenuItem>
                              </Select>
                              <FormHelperText>{errors.reporteDistibuidor}</FormHelperText>
                            </FormControl>

                          </div>
                          <div className="input_divido_children">
                            <TextField className="login-input-email"
                              type="date"
                              name="fechaReporteDistribuidor"
                              id="fechaReporteDistribuidor"
                              label="Fecha de reporte al distribuidor"
                              value={this.state.fechaReporteDistribuidor}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.fechaReporteDistribuidor}
                              error={errors.fechaReporteDistribuidor ? true : false}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                max: moment().format('YYYY-MM-DD') // Establece la fecha máxima como la fecha actual
                              }}
                              disabled={this.state.reporteDistibuidor == "Si" ? false : true}
                            />
                          </div>
                        </div>

                        <div className="input_divido">
                          <div className="input_divido_children">
                            <FormControl className="formcontrol-nuevocliente" error={errors.envioDistribuidor ? true : false}>
                              <InputLabel>¿Se envió al distribuidor?</InputLabel>
                              <Select
                                required
                                name="envioDistribuidor"
                                id="envioDistribuidor"
                                value={this.state.envioDistribuidor}
                                onChange={this.handleChange}
                                fullWidth
                              >
                                <MenuItem value="">
                                  <em></em>
                                </MenuItem>
                                <MenuItem value="Si">Sí</MenuItem>
                                <MenuItem value="No">No</MenuItem>
                              </Select>
                              <FormHelperText>{errors.envioDistribuidor}</FormHelperText>
                            </FormControl>

                          </div>
                          <div className="input_divido_children">
                            <TextField className="login-input-email"
                              type="date"
                              name="fechaEnvioDistribuidor"
                              id="fechaEnvioDistribuidor"
                              label="Fecha de envio al distribuidor"
                              value={this.state.fechaEnvioDistribuidor}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.fechaEnvioDistribuidor}
                              error={errors.fechaEnvioDistribuidor ? true : false}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                max: moment().format('YYYY-MM-DD') // Establece la fecha máxima como la fecha actual
                              }}
                              disabled={this.state.envioDistribuidor == "Si" ? false : true}
                            />
                          </div>
                        </div>

                        <FormControl className="formcontrol-nuevocliente" error={errors.disponibleEvaluacion ? true : false}>
                          <InputLabel>¿Disponible para evaluación?</InputLabel>
                          <Select
                            required
                            name="disponibleEvaluacion"
                            id="disponibleEvaluacion"
                            value={this.state.disponibleEvaluacion}
                            onChange={this.handleChange}
                            fullWidth
                          >
                            <MenuItem value="">
                              <em></em>
                            </MenuItem>
                            <MenuItem value="Si">Sí</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                          <FormHelperText>{errors.disponibleEvaluacion}</FormHelperText>
                        </FormControl>

                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <h4 className="text-title-accorion">Información del reportante</h4>
                    </AccordionSummary>

                    <AccordionDetails>
                      <div className="flex-accordion">

                        <TextField className="login-input-email"
                          required
                          type="text"
                          name="nombreReportante"
                          id="nombreReportante"
                          label="Nombres y apellidos completos del reportante"
                          value={this.state.nombreReportante}
                          onChange={this.handleChange}
                          fullWidth
                          helperText={errors.nombreReportante}
                          error={errors.nombreReportante ? true : false}
                        />
                        <div className="input_divido">
                          <div className="input_divido_children">
                            <TextField className="login-input-email"
                              required
                              type="text"
                              name="profesion"
                              id="profesion"
                              label="Profesión"
                              value={this.state.profesion}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.profesion}
                              error={errors.profesion ? true : false}
                            />
                          </div>
                          <div className="input_divido_children">
                            <TextField className="login-input-email"
                              required
                              type="text"
                              name="servicioPerteneciente"
                              id="servicioPerteneciente"
                              label="Área o servicio al que pertenece"
                              value={this.state.servicioPerteneciente}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.servicioPerteneciente}
                              error={errors.servicioPerteneciente ? true : false}
                            />
                          </div>
                        </div>



                        <div className="input_divido">
                          <div className="input_divido_children">
                            <TextField className="login-input-email"
                              type="text"
                              name="direccion"
                              id="direccion"
                              label="Dirección"
                              value={this.state.direccion}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.direccion}
                              error={errors.direccion ? true : false}
                            />
                          </div>
                          <div className="input_divido_children">
                            <TextField className="login-input-email"
                              type="text"
                              name="telefono"
                              id="telefono"
                              label="Teléfono"
                              value={this.state.telefono}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.telefono}
                              error={errors.telefono ? true : false}
                            />
                          </div>
                        </div>


                        <TextField className="login-input-email"
                          type="text"
                          name="correoInstitucional"
                          id="correoInstitucional"
                          label="Correo institucional"
                          value={this.state.correoInstitucional}
                          onChange={this.handleChange}
                          fullWidth
                          helperText={errors.correoInstitucional}
                          error={errors.correoInstitucional ? true : false}
                        />

                        <FormControl className="formcontrol-nuevocliente" error={errors.estadoReporte ? true : false}>
                          <InputLabel>Estado del caso</InputLabel>
                          <Select
                            required
                            name="estadoReporte"
                            id="estadoReporte"
                            value={this.state.estadoReporte}
                            onChange={this.handleChange}
                            fullWidth
                          >
                            <MenuItem value="">
                              <em></em>
                            </MenuItem>
                            <MenuItem value="A">Abierto</MenuItem>
                            <MenuItem value="S">En seguimiento</MenuItem>
                            <MenuItem value="C">Cerrado</MenuItem>
                          </Select>
                          <FormHelperText>{errors.estadoReporte}</FormHelperText>
                        </FormControl>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  {Object.keys(errors).length > 0 && (
                    <p>Por favor, diligencie todos los campos mínimamente obligatorios para crear un caso</p>
                  )}

                </form>
              </DialogContent>
            </div>
          )
          }
          <DialogActions>
            {this.props.UI.loading ? (
              <CircularProgress size={30} color="primary" className="login-progress" />
            ) : (
              <div>
                <MyButton tip="Cerrar" onClick={this.handleCloseEdit}>
                  <FaArrowLeft color="#D3D7DC" className="icon-formulario" />
                </MyButton>
                {this.state.aprobado.mensaje ?
                  (
                    <></>
                  ) : (
                    <MyButton tip="Confirmar edición" onClick={this.handleEdit}>
                      <FaEdit color="#03178C" className="icon-formulario" />
                    </MyButton>
                  )}
              </div>

            )}
          </DialogActions>
        </Dialog>


        {
          // Eliminar reporte
        }
        <Dialog
          open={this.state.openDelete}
          onClose={this.handleCloseDelete}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>
            {
              this.state.aprobado.mensaje ? (
                <></>
              ) : (
                <p>Confirmación para eliminar caso de tecnovigilancia</p>
              )
            }
          </DialogTitle>
          <DialogContent>
            {this.state.aprobado.mensaje ? (
              <div className='div-aprobado'>
                <div className='div-icono-aprobado'>
                  <FaCheck color="#ffffff" className='icono-aprobado' />
                </div>
                <h2 className='text-aprobado'>!Caso eliminado exitosamente¡</h2>
              </div>
            ) : (
              <p>¿Estás seguro que deseas eliminar este caso? <br /><br />
                Recuerde que la tecnovigilancia es un proceso proactivo y en conjunto con los demás usuarios del dispositivo médico.
                Así desde Alpha Biomédica, te recordamos que todos los usuarios que tengan que ver con este equipo pueden ver o
                editar este reporte, y al eliminarlo de la base de datos puede que se pierda parte importante de la trazabilidad del equipo.
              </p>
            )}
          </DialogContent>
          <DialogActions>
            {this.props.UI.loading ? (
              <CircularProgress size={30} color="primary" className="login-progress" />
            ) : (
              <div>
                <MyButton tip="Cerrar" onClick={this.handleCloseDelete}>
                  <FaArrowLeft color="#D3D7DC" className="icon-formulario" />
                </MyButton>
                {this.state.aprobado.mensaje ?
                  (
                    <></>
                  ) : (
                    <MyButton tip="Confirmar" onClick={this.handleDelete}>
                      <FaTrashAlt color="#03178C" className="icon-formulario" />
                    </MyButton>
                  )}
              </div>
            )
            }



          </DialogActions>
        </Dialog>
      </div >
    )
  }
}

TablaTecno.propTypes = {
  getCasosTecnoEquipo: PropTypes.func.isRequired,
  deleteCasoTecno: PropTypes.func.isRequired,
  editCasoTecno: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,

  data: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
};

const mapActionsToProps = {
  getCasosTecnoEquipo,
  deleteCasoTecno,
  editCasoTecno,
  clearErrors
};

const mapStateToProps = (state) => ({
  data: state.data,
  UI: state.UI,
  usuario: state.user.credentials
})



export default connect(mapStateToProps, mapActionsToProps)(TablaTecno);