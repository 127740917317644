import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MyButton from '../../../utils/MyButton';
import { FaArrowCircleLeft } from 'react-icons/fa';
import TablaTecnoCliente from './TablaTecnoCliente';

function RegistrosTecno(props) {
  const history = useHistory();
  const { clienteId } = useParams();
  
  // Acceder al estado de Redux usando useSelector
  const user = useSelector((state) => state.user);
  
  const handleGoBack = () => {
    // Redirigir según el tipo de usuario
    if (user.credentials && user.credentials.tipouser === "Tecnico") {
      history.push(`/dashboard/perfilclientetec/${clienteId}`);
    }else if(user.credentials.tipouser === "Asistencial"){
      history.push(`/dashboard/equiposasistencial`);
    } 
    else {
      // Asumimos que es "Ingeniero" u otro tipo
      history.push(`/dashboard/perfilcliente/${clienteId}`);
    }
  };

  return (
    <div>
      <div className="titulo_section">
        <div className="titulo_clientes">
          <h1 className="text-clientes">Registros de tecnovigilancia</h1>
          <hr className="clientes_separator"></hr>
        </div>

        <div className="div_boton">
          <MyButton tip="Volver" onClick={handleGoBack}>
            <FaArrowCircleLeft color="#03178C" className="icon-eliminarcliente" />
          </MyButton>
        </div>
      </div>

      <div className="div-tabla-equipos">
        <TablaTecnoCliente clienteId={clienteId} history={history}></TablaTecnoCliente>
      </div>
    </div>
  );
}

export default RegistrosTecno;