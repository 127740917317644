import React, { useState, useEffect } from 'react';
import { ButtonGroup, Button } from '@material-ui/core';
import MyButton from '../../../utils/MyButton';
import { FaArrowCircleLeft } from "react-icons/fa";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getCasoTecno, getCliente, getEquipo } from '../../../redux/actions/dataactions';
import { PDFViewer } from '@react-pdf/renderer';
import { useHistory } from 'react-router-dom';
import CasoTecnoDocuPDF from './CasoTecnoDocuPDF';
import './CasoTecnoVPDF.css';

const CasoTecnoVPDF = ({
  getCasoTecno,
  getCliente,
  getEquipo,
  data: { casotecnovigilancia, cliente, loading },
  match
}) => {
  const [verPDF, setVerPDF] = useState(false);
  const history = useHistory();

  useEffect(() => {
    // 1) Cargar caso y cliente
    getCasoTecno(match.params.docId, match.params.clienteId);
    getCliente(match.params.clienteId);
    getEquipo(match.params.equipoId);
  }, [getCasoTecno, getCliente,getEquipo, match.params.docId, match.params.clienteId,match.params.equipoId]);

  const handleOpenPDF = () => {
    setVerPDF(prev => !prev);
  };

  const goBack = () => history.goBack();

  // 2) Función que decide qué renderizar
  const renderContent = () => {
    // Si el cliente no tiene plantilla configurada
    if (!cliente?.plantillaType) {
      return (
        <div className="ct-error-message">
          <p>
            Para ver las plantillas, primero debe configurar el tipo de plantilla
            en la ficha de cliente.
          </p>
        </div>
      );
    }

    // Si tenemos datos y el usuario pidió ver el PDF
    if (casotecnovigilancia && verPDF) {
      return (
        <PDFViewer style={{ width: '100%', height: '90vh' }}>
          <CasoTecnoDocuPDF caso={casotecnovigilancia} />
        </PDFViewer>
      );
    }

    return null;
  };

  return (
    <div className="ct-pdf-wrapper">
      <div className="ct-pdf-header">
        <div className="ct-back-btn">
          <MyButton tip="Volver" onClick={goBack}>
            <FaArrowCircleLeft className="ct-back-icon" />
          </MyButton>
        </div>

        {/* 3) Mostrar botón sólo si hay plantilla */}
        {cliente?.plantillaType && (
          <ButtonGroup variant="text" color="primary">
            <Button onClick={handleOpenPDF}>
              {verPDF ? 'Ocultar PDF' : 'Ver PDF'}
            </Button>
          </ButtonGroup>
        )}
      </div>

      <div className="ct-pdf-content">
        {renderContent()}
      </div>
    </div>
  );
};

CasoTecnoVPDF.propTypes = {
  getCasoTecno: PropTypes.func.isRequired,
  getCliente: PropTypes.func.isRequired,
  getEquipo:PropTypes.func.isRequired,
  data: PropTypes.shape({
    casotecnovigilancia: PropTypes.object,
    cliente: PropTypes.object,
    loading: PropTypes.bool
  }).isRequired,
};

const mapStateToProps = state => ({
  data: state.data
});

export default connect(
  mapStateToProps,
  { getCasoTecno, getCliente,getEquipo }
)(CasoTecnoVPDF);
