import React, { Component } from 'react'
import './HistorialSolicitudCorrectivos.css'; 
import {postMtoCorrectivoConSolicitud,getSolicitudesCorrectivos,clearErrors,deleteMtoCorrectivo} from '../../../redux/actions/dataactions';
import {getRepuestos} from '../../../redux/actions/dataactions';
import {getUsuariosAsistenciales} from '../../../redux/actions/useractions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { FaArrowLeft, FaPlus, FaCheckCircle, FaCheck } from "react-icons/fa";
import MaterialTable from 'material-table';

import MyButton from '../../../utils/MyButton';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CircularProgress from '@material-ui/core/CircularProgress';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PhotoIcon from '@material-ui/icons/Photo';

export class HistorialSolicitudCorrectivos extends Component {
    constructor(){
        super();
        this.state={
            errors:{},
            open:false,
            openevidencia:false,
            idsolicitud:'',
            fechasolicitud:'',
            horasolicitud:'',
           
            fallareportada:'',
            obsolescencia:false,
            malaoperacion:false,
            malainst:false,
            accesorios:false,
            caida:false,
            desgaste:false,
            corto:false,
            sinfalla:false,
            paciente:false,
            desconocido:false,
            otro:'',
            descactividad:'',
            descfalla:'',
            observaciones:'',
            apto:'',
            retirado:'',
            repuestoId: '',
            repuestoId1: '',
            repuestoId2: '',
            nombrerepuesto:'',
            nombrerepuesto1:'',
            nombrerepuesto2:'',
            cantidad:0,
            cantidad1:0,
            cantidad2:0,
            costo:0,
            costo1:0,
            costo2:0,
            subtotal:0,
            subtotal1:0,
            subtotal2:0,
            totalrepuesto:0,
            horainicio:'',
            horafinal:'',
            firmarecibe:'',
            cargorecibe:'',
            fecha:'',
            fechafinal:'',
            solicitud:{},
            tiemporespuesta:'',
            tiemporespuestahora:'',
            imagenurl:'',
            
            // Nuevos estados para métodos de firma
            metodoRecepcion: 'manual', // método por defecto
            // Estados para firma digital
            firmaDigital: null,
            firmaGuardada: false,
            uploadingImage: false,
            nombreFirmante: '',
            cargoFirmante: '',
            // Estados para selección de usuario
            usuarioRecibe: '',
            // Estados para carga de imagen
            imagenFirma: null,
            nombreFirmaImagen: '',
            cargoFirmaImagen: '',
            aprobado: {}
        };
        this.canvasRef = React.createRef();
    };

    componentDidMount(){
        this.props.getSolicitudesCorrectivos(this.props.equipoId);
       
        // Agregar escucha de evento de redimensionamiento de ventana para el canvas
        window.addEventListener('resize', this.resizeCanvas);
    }

    componentDidUpdate(prevProps, prevState) {
        // Si se cambia el método de recepción a "firma", inicializar el canvas
        if (prevState.metodoRecepcion !== this.state.metodoRecepcion) {
            if (this.state.metodoRecepcion === "firma" && this.canvasRef.current) {
                this.initCanvas();
            } else if (this.state.metodoRecepcion === "usuario") {
                // Cargar usuarios asistenciales cuando se selecciona este método
                if (this.props.user && this.props.user.credentials && this.props.user.credentials.handle) {
                    this.props.getUsuariosAsistenciales(this.props.clienteId);
                }
            }
        }
    }

    componentWillUnmount() {
        // Limpiar eventos al desmontar el componente
        window.removeEventListener('resize', this.resizeCanvas);
    }

    static getDerivedStateFromProps(props, state) {
        const newState = {};
      
        if (props.UI.errors) {
          newState.errors = props.UI.errors;
        }
      
        if (props.UI.aprobado) {
          newState.aprobado = props.UI.aprobado;
        }
      
        return Object.keys(newState).length > 0 ? newState : null;
    }

    // Redimensionar canvas para adaptarse al contenedor
    resizeCanvas = () => {
        if (!this.canvasRef.current) return;
        
        const canvas = this.canvasRef.current;
        const ratio = Math.max(window.devicePixelRatio || 1, 1);
        const container = canvas.parentElement;
        
        canvas.width = container.clientWidth * ratio;
        canvas.height = 200 * ratio;
        canvas.style.width = `${container.clientWidth}px`;
        canvas.style.height = "200px";
        
        const ctx = canvas.getContext("2d");
        ctx.scale(ratio, ratio);
        
        // Configurar estilos de dibujo
        ctx.lineJoin = 'round';
        ctx.lineCap = 'round';
        ctx.lineWidth = 2;
        ctx.strokeStyle = 'black';
        
        // Rellenar con fondo blanco
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
    }

    // Iniciar dibujo
    startDrawing = (e) => {
        const canvas = this.canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        this.isDrawing = true;
        this.lastX = e.clientX - rect.left;
        this.lastY = e.clientY - rect.top;
    }

    // Manejar inicio táctil
    startDrawingTouch = (e) => {
        e.preventDefault();
        if (e.touches.length !== 1) return;
        
        const touch = e.touches[0];
        const canvas = this.canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        
        this.isDrawing = true;
        this.lastX = touch.clientX - rect.left;
        this.lastY = touch.clientY - rect.top;
    }

    // Dibujar en el canvas
    draw = (e) => {
        if (!this.isDrawing) return;
        
        const canvas = this.canvasRef.current;
        const ctx = canvas.getContext('2d');
        const rect = canvas.getBoundingClientRect();
        
        const currentX = e.clientX - rect.left;
        const currentY = e.clientY - rect.top;
        
        ctx.beginPath();
        ctx.moveTo(this.lastX, this.lastY);
        ctx.lineTo(currentX, currentY);
        ctx.stroke();
        
        this.lastX = currentX;
        this.lastY = currentY;
    }

    // Manejar movimiento táctil
    drawTouch = (e) => {
        e.preventDefault();
        if (!this.isDrawing || e.touches.length !== 1) return;
        
        const touch = e.touches[0];
        const canvas = this.canvasRef.current;
        const ctx = canvas.getContext('2d');
        const rect = canvas.getBoundingClientRect();
        
        const currentX = touch.clientX - rect.left;
        const currentY = touch.clientY - rect.top;
        
        ctx.beginPath();
        ctx.moveTo(this.lastX, this.lastY);
        ctx.lineTo(currentX, currentY);
        ctx.stroke();
        
        this.lastX = currentX;
        this.lastY = currentY;
    }

    // Detener dibujo
    stopDrawing = () => {
        this.isDrawing = false;
    }

    // Limpiar firma digital
    clearSignature = () => {
        if (!this.canvasRef.current) return;
        
        const canvas = this.canvasRef.current;
        const ctx = canvas.getContext('2d');
        
        // Limpiar canvas
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        
        this.setState({ firmaDigital: null });
    }

    // Verificar si el canvas está vacío
    isCanvasEmpty = () => {
        if (!this.canvasRef.current) return true;
        
        const canvas = this.canvasRef.current;
        const ctx = canvas.getContext('2d');
        
        const pixelData = ctx.getImageData(0, 0, canvas.width, canvas.height).data;
        
        // Comprobar si todos los datos de píxeles son blancos (255, 255, 255, 255)
        for (let i = 0; i < pixelData.length; i += 4) {
            // Comprobar valores RGB (omitir alfa)
            if (pixelData[i] !== 255 || pixelData[i + 1] !== 255 || pixelData[i + 2] !== 255) {
                return false; // Se encontró un píxel no blanco
            }
        }
        
        return true; // El canvas está vacío (todo blanco)
    }

    // Guardar firma digital
    saveSignature = () => {
        if (!this.canvasRef.current) return;
        
        if (!this.isCanvasEmpty()) {
            const signatureData = this.canvasRef.current.toDataURL('image/png');
            
            // Guardar firma y establecer firmaGuardada a true
            this.setState({ 
                firmaDigital: signatureData,
                firmaGuardada: true, // Indicador visual de que la firma se guardó
                errors: {
                    ...this.state.errors,
                    firmaDigital: null
                }
            });
            
            // Mostrar mensaje de confirmación que desaparece después de unos segundos
            setTimeout(() => {
                this.setState({ firmaGuardada: false });
            }, 3000);
        } else {
            // Mostrar error - firma vacía
            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    firmaDigital: "Es necesario firmar antes de guardar"
                }
            }));
        }
    }

    // Manejar carga de imagen
    handleImageButtonClick = () => {
        // Crear un input de archivo temporal
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*';
        
        // Configurar el manejador de eventos antes de activar el input
        fileInput.addEventListener('change', (event) => {
            const file = event.target.files[0];
            if (file) {
                this.setState({ uploadingImage: true });
                
                const reader = new FileReader();
                
                reader.onload = (e) => {
                    this.setState({ 
                        imagenFirma: e.target.result,
                        uploadingImage: false,
                        errors: {
                            ...this.state.errors,
                            imagenFirma: null
                        }
                    });
                };
                
                reader.onerror = () => {
                    this.setState({ 
                        uploadingImage: false,
                        errors: {
                            ...this.state.errors,
                            imagenFirma: "Error al cargar la imagen"
                        }
                    });
                };
                
                reader.readAsDataURL(file);
            }
        });
        
        // Simular clic en el input
        fileInput.click();
    }

    // Obtener información de usuario por ID desde props
    getUserInfo = (userId, field) => {
        const { usuariosAsistenciales } = this.props.user;
        
        if (!usuariosAsistenciales) return '';
        
        const user = usuariosAsistenciales.find(u => u.idUsuario === userId);
        
        // Si el campo es 'cargo' pero no existe, proporcionar un valor predeterminado
        if (field === 'cargo' && (!user || !user[field])) {
            return 'Asistencial';
        }
        
        return user ? user[field] : '';
    }

    // Manejar cambio de método
    handleMethodChange = (event) => {
        const method = event.target.value;
        this.setState({ metodoRecepcion: method });
        
        // Si se elige la selección de usuario, cargar la lista de usuarios asistenciales
        if (method === 'usuario') {
            this.props.getUsuariosAsistenciales(this.props.clienteId);
        }
    }

    // Inicializar el canvas para firma
    initCanvas = () => {
        if (!this.canvasRef.current) return;
        
        const canvas = this.canvasRef.current;
        const ctx = canvas.getContext('2d');
        
        // Configurar canvas
        this.resizeCanvas();
        
        // Limpiar el canvas
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        
        // Configurar estado de dibujo
        this.isDrawing = false;
        this.lastX = 0;
        this.lastY = 0;
        
        // Configurar eventos para dibujo
        canvas.addEventListener('mousedown', this.startDrawing);
        canvas.addEventListener('mousemove', this.draw);
        canvas.addEventListener('mouseup', this.stopDrawing);
        canvas.addEventListener('mouseout', this.stopDrawing);
        
        // Eventos táctiles
        canvas.addEventListener('touchstart', this.startDrawingTouch);
        canvas.addEventListener('touchmove', this.drawTouch);
        canvas.addEventListener('touchend', this.stopDrawing);
    }
    
    handleOpen = (rawdata) => {
        const rawdatastate = rawdata; 
        console.log(rawdata);
        console.log(rawdatastate);
       
        let fecharaw = new Date(rawdata.datecreation); 
        
        // Formatear la fecha a "YYYY-MM-DD"
        let fecha = fecharaw.toISOString().split('T')[0];
        
        // Formatear la hora (manteniendo el formato original)
        let hora = fecharaw.toLocaleTimeString();
        
        this.setState({
            open: true,
            aprobado:{},
            errors:{},
            solicitud: rawdatastate,
            solicitudid: rawdatastate.solicitudid,
            fechasolicitud: fecha,
            fallareportada: rawdatastate.fallareportada,
            horasolicitud: hora
        });
        
        // Cargar los repuestos al abrir el diálogo
        if (this.props.user && this.props.user.credentials) {
              const { credentials } = this.props.user;
              
              if (credentials.tipouser === "Tecnico") {
                  // Si es técnico, usar handleuser para obtener repuestos
                  console.log('Es Tecnico')
                  console.log('Handle User'+credentials.handleuser);
                  if (credentials.handleuser) {
                      this.props.getRepuestos(credentials.handleuser);
                  }
              } else if (credentials.tipouser === "Ingeniero") {
                console.log('Es Ingeniero')
                  // Si es ingeniero, usar handle para obtener repuestos
                  if (credentials.handle) {
                      this.props.getRepuestos(credentials.handle);
                  }
              }
          }
        
        console.log(this.state);
    };

    handleOpenEvidencia = (imagenurl) => {
        console.log('Imagen URL: ' + imagenurl);
        this.setState({ openevidencia: true, imagenurl: imagenurl });
    };
        
    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
        
        // Si el evento proviene de un select de repuesto
        if (event.target.name === 'repuestoId' || event.target.name === 'repuestoId1' || event.target.name === 'repuestoId2') {
            const repuestoSeleccionado = this.props.data.repuestos.find(
                repuesto => repuesto.repuestoId === event.target.value
            );
            
            if (repuestoSeleccionado) {
                let nombreField, costoField;
                
                if (event.target.name === 'repuestoId') {
                    nombreField = 'nombrerepuesto';
                    costoField = 'costo';
                } else if (event.target.name === 'repuestoId1') {
                    nombreField = 'nombrerepuesto1';
                    costoField = 'costo1';
                } else {
                    nombreField = 'nombrerepuesto2';
                    costoField = 'costo2';
                }
                
                this.setState({ 
                    [nombreField]: repuestoSeleccionado.nombre,
                    [costoField]: repuestoSeleccionado.valorunitario 
                });
            }
        }
    };

    handleClose = () => {
        if (this.state.aprobado && this.state.aprobado.mensaje) {
            console.log(this.state.aprobado);
            
            this.setState({ open: false, errors: {}, aprobado: {} });
            // Recargar la lista de solicitudes
            this.props.getSolicitudesCorrectivos(this.props.equipoId);
        } else {
            this.setState({ open: false, errors: {},aprobado:{} });
        }
    };

    handleCloseEvidencia = () => {
        this.setState({ openevidencia: false, errors: {}, imagenurl: '' });
    };

    handlePostMto = () => {
        // Determinar qué método de recepción usar y preparar los datos en consecuencia
        let receptorInfo = {};
        
        switch(this.state.metodoRecepcion) {
            case 'manual':
                receptorInfo = {
                    firmarecibe: this.state.firmarecibe,
                    cargorecibe: this.state.cargorecibe,
                    metodoRecepcion: 'manual'
                };
                break;
                
            case 'firma':
                // Obtener los datos de firma del canvas
                if (this.canvasRef.current && !this.isCanvasEmpty()) {
                  const signatureData = this.canvasRef.current.toDataURL('image/png');
                  
                  receptorInfo = {
                      nombreFirmante: this.state.nombreFirmante,
                      cargoFirmante: this.state.cargoFirmante,
                      imagenFirma: signatureData,
                      metodoRecepcion: 'firma'
                  };
                } else {
                    // Mostrar error si no hay firma
                    this.setState(prevState => ({
                        errors: {
                            ...prevState.errors,
                            firmaDigital: "Es necesario firmar antes de guardar"
                        }
                    }));
                    return; // Salir sin enviar
                }
                break;
                
            case 'usuario':
                if (this.state.usuarioRecibe) {
                    receptorInfo = {
                        usuarioRecibe: this.state.usuarioRecibe,
                        metodoRecepcion: 'usuario'
                    };
                } else {
                    // Mostrar error si no se seleccionó usuario
                    this.setState(prevState => ({
                        errors: {
                            ...prevState.errors,
                            usuarioRecibe: "Debe seleccionar un usuario"
                        }
                    }));
                    return; // Salir sin enviar
                }
                break;
                
            case 'imagen':
                if (this.state.imagenFirma) {
                    receptorInfo = {
                        nombreFirmaImagen: this.state.nombreFirmaImagen,
                        cargoFirmaImagen: this.state.cargoFirmaImagen,
                        imagenFirma: this.state.imagenFirma,
                        metodoRecepcion: 'imagen'
                    };
                } else {
                    // Mostrar error si no se cargó imagen
                    this.setState(prevState => ({
                        errors: {
                            ...prevState.errors,
                            imagenFirma: "Se requiere una imagen de firma"
                        }
                    }));
                    return; // Salir sin enviar
                }
                break;
                
            default:
                receptorInfo = {
                    firmarecibe: this.state.firmarecibe,
                    cargorecibe: this.state.cargorecibe,
                    metodoRecepcion: 'manual'
                };
        }
        
        // Preparar el objeto completo de mantenimiento correctivo
        const newMtoCorrectivo = {
            fechasolicitud: this.state.fechasolicitud,
            horasolicitud: this.state.horasolicitud,
            
            fallareportada: this.state.fallareportada,
            obsolescencia: this.state.obsolescencia,
            malaoperacion: this.state.malaoperacion,
            malainst: this.state.malainst,
            accesorios: this.state.accesorios,
            caida: this.state.caida,
            desgaste: this.state.desgaste,
            corto: this.state.corto,
            sinfalla: this.state.sinfalla,
            paciente: this.state.paciente,
            desconocido: this.state.desconocido,
            otro: this.state.otro,
            descactividad: this.state.descactividad,
            descfalla: this.state.descfalla,
            apto: this.state.apto,
            retirado: this.state.retirado,
            repuestoId: this.state.repuestoId,
            repuestoId1: this.state.repuestoId1,
            repuestoId2: this.state.repuestoId2,
            nombrerepuesto: this.state.nombrerepuesto,
            nombrerepuesto1: this.state.nombrerepuesto1,
            nombrerepuesto2: this.state.nombrerepuesto2,
            cantidad: this.state.cantidad,
            cantidad1: this.state.cantidad1,
            cantidad2: this.state.cantidad2,
            costo: this.state.costo,
            costo1: this.state.costo1,
            costo2: this.state.costo2,
            subtotal: this.state.subtotal,
            subtotal1: this.state.subtotal1,
            subtotal2: this.state.subtotal2, 
            totalrepuesto: this.state.totalrepuesto,
            observaciones: this.state.observaciones,
            horainicio: this.state.horainicio,
            horafinal: this.state.horafinal,
            firmarecibe: this.state.firmarecibe,
            cargorecibe: this.state.cargorecibe,
            fecha: this.state.fecha,
            fechafinal: this.state.fechafinal,
            solicitud: this.state.solicitud,
            ...receptorInfo  // Incluir la información del método de recepción
        };
     
        let segundosatiempo = (s) => {
            const addZ = (n) => {
                return (n < 10 ? '0' : '') + n;
            }
            var ms = s % 1000;
            s = (s - ms) / 1000;
            var secs = s % 60;
            s = (s - secs) / 60;
            var mins = s % 60;
            var hrs = (s - mins) / 60;
            return addZ(hrs) + ':' + addZ(mins) + ':' + addZ(secs) + '.' + addZ(ms);
        }

        // Cálculo del tiempo de respuesta
        const fechaHoraSolicitud = new Date(`${newMtoCorrectivo.fechasolicitud}T${newMtoCorrectivo.horasolicitud}`);
        const fechaHoraInicio = new Date(`${newMtoCorrectivo.fecha}T${newMtoCorrectivo.horainicio}`);
        const tiempoRespuesta = fechaHoraInicio.getTime() - fechaHoraSolicitud.getTime();
        const tiempoRespuestaFormateado = segundosatiempo(tiempoRespuesta);
        
        // Cálculo del tiempo de reparación
        const fechaHoraFinal = new Date(`${newMtoCorrectivo.fechafinal}T${newMtoCorrectivo.horafinal}`);
        const tiempoReparacion = fechaHoraFinal.getTime() - fechaHoraInicio.getTime();
        const tiempoReparacionFormateado = segundosatiempo(tiempoReparacion);
        
        // Añadir los nuevos campos al objeto newMtoCorrectivo
        newMtoCorrectivo.tiemporespuesta = tiempoRespuesta;
        newMtoCorrectivo.tiemporespuestahora = tiempoRespuestaFormateado;
        newMtoCorrectivo.tiemporeparacion = tiempoReparacion;
        newMtoCorrectivo.tiemporeparacionformateado = tiempoReparacionFormateado;
        
        console.log(newMtoCorrectivo);
        console.log(newMtoCorrectivo.solicitud.equipoid);
        console.log(newMtoCorrectivo.solicitud.idprestador);
        
       this.props.postMtoCorrectivoConSolicitud(
            newMtoCorrectivo, 
            this.props.equipoId, 
            this.props.clienteId, 
            this.state.solicitudid
        );
    }

  render() {
    const { solicitudescorr, loadingsolcorr, repuestos } = this.props.data; 
    const {errors}=this.state;
    const { UI:{loading}}=this.props; 
  

    this.state.subtotal=this.state.cantidad*this.state.costo;
    this.state.subtotal1=this.state.cantidad1*this.state.costo1;
    this.state.subtotal2=this.state.cantidad2*this.state.costo2;
    this.state.totalrepuesto=this.state.subtotal+this.state.subtotal1+this.state.subtotal2;
      // Opciones de repuestos para los selects
      const repuestosOptions = repuestos && repuestos.length > 0 ? (
        repuestos.map(repuesto => (
            <MenuItem key={repuesto.repuestoId} value={repuesto.repuestoId}>
                {repuesto.nombre}
            </MenuItem>
        ))
    ) : (
        <MenuItem value=""><em>No hay repuestos disponibles</em></MenuItem>
    );
        const tableIcons = {
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} style={{ color: '#03178c' }}/>),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
            ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
            AssignmentIcon:forwardRef((props, ref) => <AssignmentIcon {...props} ref={ref} />),
            photo: forwardRef((props, ref) => <PhotoIcon {...props} ref={ref} style={{ color: '#03178c' }} />)
          };
        
        const columns=[
            {title:'Fecha',field:'datecreation'},
            {title:'Solicitante',field:'nombresolicita'},
            {title:'Falla',field:'fallareportada'},
            {title:'Estado',field:'estado'}
           
        ]


        let mapeosolicitudescorrectivos=!loadingsolcorr ?(
            <div>
       <MaterialTable
               columns={columns}
               data={solicitudescorr}
               title={
                <h2 style={{ fontSize: '16px', color: '#03178c',fontWeight:'600' }}>Solicitudes de Mantenimiento Correctivo</h2>
              }
              icons={tableIcons}
              options={{
                cellStyle: {
                    fontSize: 12,
                    fontWeight:500,
                    color:'#696B6D'
                  
                 
                  },
                  headerStyle: {
                    fontSize: 14,
                    fontWeight: 'bolder',
                    color:"#03178c" // Ajusta el tamaño de fuente según tus necesidades
                  }
              
              }}
               actions={[
                
                rowData => ({
                  icon: props => {
                    const disabledColor = rowData.estado === 'Atendida' ? '#CCCCCC' : '#03178c'; // Define el color deshabilitado y habilitado
                    return (
                        <AddBox {...props} style={{ color: disabledColor }} />
                    );
                  },
                  tooltip: 'Hacer Mantenimiento Correctivo',
                  onClick: (event, rowData) => {
                     this.handleOpen(rowData);
                  },
                  disabled: rowData.estado==='Atendida'
                }),
                {
                  title: "EvidenciaCargada",
                  icon: tableIcons.photo,
                  tooltip: 'Ver evidencia cargada',
                  onClick: (event, rowData) => {
                    this.handleOpenEvidencia(rowData.imageUrl);
                  },
                }
             
                ]}
             
             
       
               >
       
               </MaterialTable>
   
        
            </div>
              
   
           ):(  <div className="div_cargando">
               <CircularProgress size={50}color="primary" className="login-progress" />
               </div>);
    return (
      <div className="listamtoprog-container">
              
            <div className="listamtoprog-table">
            {mapeosolicitudescorrectivos}
            </div>


            <Dialog
             open={this.state.open}
             onClose={this.handleClose}
             fullWidth
             maxWidth="sm"
            >
            {this.state.aprobado && this.state.aprobado.mensaje ? (
                  <div className='div-aprobado'>
                    <div className='div-icono-aprobado'>
                      <FaCheck color="#ffffff" className='icono-aprobado'/>
                    </div>
                    <h2 className='text-aprobado'>Reporte Cargado Exitosamente</h2>
                  </div>
                ) : (<div>
                    <DialogTitle>Realizar Reporte de Mantenimiento Correctivo</DialogTitle>
                    <DialogContent>
                        <form>
                            <h4>Información Solicitud</h4>
                            <div className="div-input-doble-mtoprog">
                                <TextField className="login-input-email"
                                    required
                                    type="text"
                                    name="fechasolicitud"
                                    id="fechasolicitud"
                                    label="Fecha de Solicitud"
                                    value={this.state.fechasolicitud}
                                    fullWidth
                                    InputProps={{
                                        readOnly: true,
                                    }}                  
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <TextField className="login-input-email"
                                    required
                                    type="text"
                                    name="horasolicitud"
                                    id="horasolicitud"
                                    label="Hora de Solicitud"
                                    value={this.state.horasolicitud}
                                    fullWidth
                                    InputProps={{
                                        readOnly: true,
                                    }}                  
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                           
                            <TextField className="login-input-email"
                                multiline
                                type="Text"
                                name="fallareportada"
                                id="fallareportada"
                                label="Falla Reportada"
                                rows={4}
                                value={this.state.fallareportada}
                                onChange={this.handleChange}
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                            <h4 className="text-title-accorion">Causas de la Falla</h4>

                            <div className="div-accordion-container">
                                <div className="div-input-doble-mtoprog">
                                    <FormControl className="formcontrol-nuevomto">
                                        <InputLabel>Obsolescencia</InputLabel>
                                        <Select required name="obsolescencia" id="obsolescencia" value={this.state.obsolescencia} onChange={this.handleChange}
                                            fullWidth>
                                            <MenuItem value=""><em></em></MenuItem>
                                            <MenuItem value={true}>Si</MenuItem>
                                            <MenuItem value={false}>No</MenuItem> 
                                        </Select>
                                        <FormHelperText>{errors.obsolescencia}</FormHelperText>
                                    </FormControl>  
                                    <FormControl className="formcontrol-nuevomto">
                                        <InputLabel>Mala Operación</InputLabel>
                                        <Select required name="malaoperacion" id="malaoperacion" value={this.state.malaoperacion} onChange={this.handleChange}
                                            fullWidth>
                                            <MenuItem value=""><em></em></MenuItem>
                                            <MenuItem value={true}>Si</MenuItem>
                                            <MenuItem value={false}>No</MenuItem>
                                        </Select>
                                        <FormHelperText>{errors.malaoperacion}</FormHelperText>
                                    </FormControl> 
                                </div>
                                <div className="div-input-doble-mtoprog">
                                    <FormControl className="formcontrol-nuevomto">
                                        <InputLabel>Mala Instalación</InputLabel>
                                        <Select required name="malainst" id="malainst" value={this.state.malainst} onChange={this.handleChange}
                                            fullWidth>
                                            <MenuItem value=""><em></em></MenuItem>
                                            <MenuItem value={true}>Si</MenuItem>
                                            <MenuItem value={false}>No</MenuItem> 
                                        </Select>
                                        <FormHelperText>{errors.malainst}</FormHelperText>
                                    </FormControl>  
                                    <FormControl className="formcontrol-nuevomto">
                                        <InputLabel>Desgaste</InputLabel>
                                        <Select required name="desgaste" id="desgaste" value={this.state.desgaste} onChange={this.handleChange}
                                            fullWidth>
                                            <MenuItem value=""><em></em></MenuItem>
                                            <MenuItem value={true}>Si</MenuItem>
                                            <MenuItem value={false}>No</MenuItem>
                                        </Select>
                                        <FormHelperText>{errors.desgaste}</FormHelperText>
                                    </FormControl> 
                                </div>
                                
                                <div className="div-input-doble-mtoprog">
                                    <FormControl className="formcontrol-nuevomto">
                                        <InputLabel>Caída</InputLabel>
                                        <Select required name="caida" id="caida" value={this.state.caida} onChange={this.handleChange}
                                            fullWidth>
                                            <MenuItem value=""><em></em></MenuItem>
                                            <MenuItem value={true}>Si</MenuItem>
                                            <MenuItem value={false}>No</MenuItem> 
                                        </Select>
                                        <FormHelperText>{errors.caida}</FormHelperText>
                                    </FormControl>  
                                    <FormControl className="formcontrol-nuevomto">
                                        <InputLabel>Corto Circuito</InputLabel>
                                        <Select required name="corto" id="corto" value={this.state.corto} onChange={this.handleChange}
                                            fullWidth>
                                            <MenuItem value=""><em></em></MenuItem>
                                            <MenuItem value={true}>Si</MenuItem>
                                            <MenuItem value={false}>No</MenuItem>
                                        </Select>
                                        <FormHelperText>{errors.corto}</FormHelperText>
                                    </FormControl> 
                                </div>
                                
                                <div className="div-input-doble-mtoprog">
                                    <FormControl className="formcontrol-nuevomto">
                                        <InputLabel>Accesorios</InputLabel>
                                        <Select required name="accesorios" id="accesorios" value={this.state.accesorios} onChange={this.handleChange}
                                            fullWidth>
                                            <MenuItem value=""><em></em></MenuItem>
                                            <MenuItem value={true}>Si</MenuItem>
                                            <MenuItem value={false}>No</MenuItem> 
                                        </Select>
                                        <FormHelperText>{errors.accesorios}</FormHelperText>
                                    </FormControl>  
                                    <FormControl className="formcontrol-nuevomto">
                                        <InputLabel>Paciente</InputLabel>
                                        <Select required name="paciente" id="paciente" value={this.state.paciente} onChange={this.handleChange}
                                            fullWidth>
                                            <MenuItem value=""><em></em></MenuItem>
                                            <MenuItem value={true}>Si</MenuItem>
                                            <MenuItem value={false}>No</MenuItem>
                                        </Select>
                                        <FormHelperText>{errors.paciente}</FormHelperText>
                                    </FormControl> 
                                </div>
                                
                                <div className="div-input-doble-mtoprog">
                                    <FormControl className="formcontrol-nuevomto">
                                        <InputLabel>Sin Falla</InputLabel>
                                        <Select required name="sinfalla" id="sinfalla" value={this.state.sinfalla} onChange={this.handleChange}
                                            fullWidth>
                                            <MenuItem value=""><em></em></MenuItem>
                                            <MenuItem value={true}>Si</MenuItem>
                                            <MenuItem value={false}>No</MenuItem> 
                                        </Select>
                                        <FormHelperText>{errors.sinfalla}</FormHelperText>
                                    </FormControl>  
                                    <FormControl className="formcontrol-nuevomto">
                                        <InputLabel>Desconocido</InputLabel>
                                        <Select required name="desconocido" id="desconocido" value={this.state.desconocido} onChange={this.handleChange}
                                            fullWidth>
                                            <MenuItem value=""><em></em></MenuItem>
                                            <MenuItem value={true}>Si</MenuItem>
                                            <MenuItem value={false}>No</MenuItem>
                                        </Select>
                                        <FormHelperText>{errors.desconocido}</FormHelperText>
                                    </FormControl> 
                                </div>
                                <TextField className="login-input-email"
                                    multiline
                                    type="Text"
                                    name="otro"
                                    id="otro"
                                    label="Otro"
                                    rows={1}
                                    value={this.state.otro}
                                    onChange={this.handleChange}
                                    fullWidth
                                    helperText={errors.otro}
                                    error={errors.otro?true:false}
                                />
                            </div>

                            <h4 className="text-title-accorion">Descripción de la Actividad</h4>

                            <div className="div-accordion-container">
                                <TextField className="login-input-email"
                                    multiline
                                    type="Text"
                                    name="descfalla"
                                    id="descfalla"
                                    label="Descripción de la Falla Encontrada"
                                    rows={4}
                                    value={this.state.descfalla}
                                    onChange={this.handleChange}
                                    fullWidth
                                    helperText={errors.descfalla}
                                    error={errors.descfalla?true:false}
                                />
                                <TextField className="login-input-email"
                                    multiline
                                    type="Text"
                                    name="descactividad"
                                    id="descactividad"
                                    label="Descripción de la Actividad Realizada"
                                    rows={4}
                                    value={this.state.descactividad}
                                    onChange={this.handleChange}
                                    fullWidth
                                    helperText={errors.descactividad}
                                    error={errors.descactividad?true:false}
                                />
                                <TextField className="login-input-email"
                                    multiline
                                    type="Text"
                                    name="observaciones"
                                    id="observaciones"
                                    label="Observaciones"
                                    rows={4}
                                    value={this.state.observaciones}
                                    onChange={this.handleChange}
                                    fullWidth
                                    helperText={errors.observaciones}
                                    error={errors.observaciones?true:false}
                                />
                            </div>
                        
                            <h4 className="text-title-accorion">Repuestos Utilizados</h4>
                            
                            <div className="div-accordion-container">
                                {/* Primer repuesto */}
                                <div className="repuestos-fila">
                                    <div className="repuesto-columna-nombre">
                                        <FormControl className="repuesto-selector" fullWidth>
                                            <InputLabel>Repuesto</InputLabel>
                                            <Select
                                                name="repuestoId"
                                                id="repuestoId"
                                                value={this.state.repuestoId}
                                                onChange={this.handleChange}
                                                fullWidth
                                            >
                                                <MenuItem value=""><em>Seleccione un repuesto</em></MenuItem>
                                                {repuestosOptions}
                                            </Select>
                                            <FormHelperText>{errors.repuestoId}</FormHelperText>
                                        </FormControl>
                                    </div>
                                    <div className="repuesto-columna">
                                        <TextField 
                                            className="repuesto-campo"
                                            type="Number"
                                            name="cantidad"
                                            id="cantidad"
                                            label="Cantidad"
                                            value={this.state.cantidad}
                                            onChange={this.handleChange}
                                            fullWidth
                                            helperText={errors.cantidad}
                                            error={errors.cantidad?true:false}
                                        />
                                    </div>
                                    <div className="repuesto-columna">
                                        <TextField 
                                            className="repuesto-campo"
                                            type="Text"
                                            name="costo"
                                            id="costo"
                                            label="Costo"
                                            value={this.state.costo}
                                            onChange={this.handleChange}
                                            fullWidth
                                            helperText={errors.costo}
                                            error={errors.costo?true:false}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </div>
                                    <div className="repuesto-columna">
                                        <TextField 
                                            className="repuesto-campo"
                                            type="Text"
                                            name="subtotal"
                                            id="subtotal"
                                            label="subtotal"
                                            value={this.state.subtotal}
                                            onChange={this.handleChange}
                                            fullWidth
                                            helperText={errors.subtotal}
                                            error={errors.subtotal?true:false}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </div>
                                </div>
                            
                                {/* Segundo repuesto */}
                                <div className="repuestos-fila">
                                    <div className="repuesto-columna-nombre">
                                        <FormControl className="repuesto-selector" fullWidth>
                                            <InputLabel>Repuesto</InputLabel>
                                            <Select
                                                name="repuestoId1"
                                                id="repuestoId1"
                                                value={this.state.repuestoId1}
                                                onChange={this.handleChange}
                                                fullWidth
                                            >
                                                <MenuItem value=""><em>Seleccione un repuesto</em></MenuItem>
                                                {repuestosOptions}
                                            </Select>
                                            <FormHelperText>{errors.repuestoId1}</FormHelperText>
                                        </FormControl>
                                    </div>
                                    <div className="repuesto-columna">
                                        <TextField 
                                            className="repuesto-campo"
                                            type="Number"
                                            name="cantidad1"
                                            id="cantidad1"
                                            label="Cantidad"
                                            value={this.state.cantidad1}
                                            onChange={this.handleChange}
                                            fullWidth
                                            helperText={errors.cantidad1}
                                            error={errors.cantidad1?true:false}
                                        />
                                    </div>
                                    <div className="repuesto-columna">
                                        <TextField 
                                            className="repuesto-campo"
                                            type="Text"
                                            name="costo1"
                                            id="costo1"
                                            label="Costo"
                                            value={this.state.costo1}
                                            onChange={this.handleChange}
                                            fullWidth
                                            helperText={errors.costo1}
                                            error={errors.costo1?true:false}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </div>
                                    <div className="repuesto-columna">
                                        <TextField 
                                            className="repuesto-campo"
                                            type="Text"
                                            name="subtotal1"
                                            id="subtotal1"
                                            label="subtotal"
                                            value={this.state.subtotal1}
                                            onChange={this.handleChange}
                                            fullWidth
                                            helperText={errors.subtotal1}
                                            error={errors.subtotal1?true:false}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </div>
                                </div>
                            
                                {/* Tercer repuesto */}
                                <div className="repuestos-fila">
                                    <div className="repuesto-columna-nombre">
                                        <FormControl className="repuesto-selector" fullWidth>
                                            <InputLabel>Repuesto</InputLabel>
                                            <Select
                                                name="repuestoId2"
                                                id="repuestoId2"
                                                value={this.state.repuestoId2}
                                                onChange={this.handleChange}
                                                fullWidth
                                            >
                                                <MenuItem value=""><em>Seleccione un repuesto</em></MenuItem>
                                                {repuestosOptions}
                                            </Select>
                                            <FormHelperText>{errors.repuestoId2}</FormHelperText>
                                        </FormControl>
                                    </div>
                                    <div className="repuesto-columna">
                                        <TextField 
                                            className="repuesto-campo"
                                            type="Number"
                                            name="cantidad2"
                                            id="cantidad2"
                                            label="Cantidad"
                                            value={this.state.cantidad2}
                                            onChange={this.handleChange}
                                            fullWidth
                                            helperText={errors.cantidad2}
                                            error={errors.cantidad2?true:false}
                                        />
                                    </div>
                                    <div className="repuesto-columna">
                                        <TextField 
                                            className="repuesto-campo"
                                            type="Text"
                                            name="costo2"
                                            id="costo2"
                                            label="Costo"
                                            value={this.state.costo2}
                                            onChange={this.handleChange}
                                            fullWidth
                                            helperText={errors.costo2}
                                            error={errors.costo2?true:false}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </div>
                                    <div className="repuesto-columna">
                                        <TextField 
                                            className="repuesto-campo"
                                            type="Text"
                                            name="subtotal2"
                                            id="subtotal2"
                                            label="Subtotal"
                                            value={this.state.subtotal2}
                                            onChange={this.handleChange}
                                            fullWidth
                                            helperText={errors.subtotal2}
                                            error={errors.subtotal2?true:false}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </div>
                                </div>
                            
                                <div>
                                    <a>Total: ${this.state.totalrepuesto}</a>
                                </div>
                            </div>

                            <h4 className="text-title-accorion">Estado del Equipo</h4>

                            <div className="div-accordion-container">
                                <FormControl className="formcontrol-nuevomto">
                                    <InputLabel>¿Equipo Apto para su Uso?</InputLabel>
                                    <Select name="apto" id="apto" value={this.state.apto} onChange={this.handleChange}
                                        fullWidth>
                                        <MenuItem value=""><em></em></MenuItem>
                                        <MenuItem value={true}>Si</MenuItem>
                                        <MenuItem value={false}>No</MenuItem>
                                    </Select>
                                    <FormHelperText>{errors.apto}</FormHelperText>
                                </FormControl> 
                                <FormControl className="formcontrol-nuevomto">
                                    <InputLabel>¿El Equipo debe ser Retirado?</InputLabel>
                                    <Select name="retirado" id="retirado" value={this.state.retirado} onChange={this.handleChange}
                                        fullWidth>
                                        <MenuItem value=""><em></em></MenuItem>
                                        <MenuItem value={true}>Si</MenuItem>
                                        <MenuItem value={false}>No</MenuItem>
                                    </Select>
                                    <FormHelperText>{errors.retirado}</FormHelperText>
                                </FormControl> 
                            </div>

                            <h4 className="text-title-accorion">Fecha y Hora</h4>

                            <div className="div-accordion-container">
                                <div className="div-input-doble-mtoprog">
                                    <TextField className="login-input-email"
                                        required
                                        type="date"
                                        name="fecha"
                                        id="fecha"
                                        label="Fecha Inicio"
                                        value={this.state.fecha}
                                        onChange={this.handleChange}
                                        min="2019-01-01" max="2025-01-01"
                                        fullWidth
                                        helperText={errors.fecha}
                                        error={errors.fecha?true:false}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    <TextField className="login-input-email"
                                        required
                                        type="date"
                                        name="fechafinal"
                                        id="fechafinal"
                                        label="Fecha Final"
                                        value={this.state.fechafinal}
                                        onChange={this.handleChange}
                                        min="2019-01-01" max="2025-01-01"
                                        fullWidth
                                        helperText={errors.fechafinal}
                                        error={errors.fechafinal?true:false}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                                <div className="div-input-doble-mtoprog">
                                    <TextField className="login-input-email"
                                        required
                                        type="time"
                                        name="horainicio"
                                        id="horainicio"
                                        label="Hora de Inicio"
                                        value={this.state.horainicio}
                                        onChange={this.handleChange}
                                        fullWidth
                                        helperText={errors.horainicio}
                                        error={errors.horainicio?true:false}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    <TextField className="login-input-email"
                                        required
                                        type="time"
                                        name="horafinal"
                                        id="horafinal"
                                        label="Hora Final"
                                        value={this.state.horafinal}
                                        onChange={this.handleChange}
                                        fullWidth
                                        helperText={errors.horafinal}
                                        error={errors.horafinal?true:false}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                            </div>

                            <h4 className="text-title-accorion">Personal que recibe la actividad</h4>

                            <div className="div-accordion-container">
                                {/* Selección de método */}
                                <FormControl className="formcontrol-nuevomto" fullWidth>
                                    <InputLabel>Método de recepción</InputLabel>
                                    <Select
                                        name="metodoRecepcion"
                                        id="metodoRecepcion"
                                        value={this.state.metodoRecepcion || "manual"}
                                        onChange={(e) => {
                                            this.handleChange(e);
                                            this.handleMethodChange(e);
                                        }}
                                        fullWidth
                                    >
                                        <MenuItem value="manual">Ingreso manual</MenuItem>
                                        <MenuItem value="firma">Firma digital</MenuItem>
                                        <MenuItem value="usuario">Seleccionar usuario</MenuItem>
                                        <MenuItem value="imagen">Cargar imagen</MenuItem>
                                    </Select>
                                    <FormHelperText>{errors.metodoRecepcion}</FormHelperText>
                                </FormControl>

                                {/* Diferentes componentes basados en la selección */}
                                {this.state.metodoRecepcion === "manual" || !this.state.metodoRecepcion ? (
                                    <div className="div-firma-manual">
                                        <TextField 
                                            className="repuesto-campo"
                                            required
                                            type="Text"
                                            name="firmarecibe"
                                            id="firmarecibe"
                                            label="Nombre de Quien Recibe La Actividad"
                                            value={this.state.firmarecibe}
                                            onChange={this.handleChange}
                                            fullWidth
                                            helperText={errors.firmarecibe}
                                            error={errors.firmarecibe ? true : false}
                                        />

                                        <TextField 
                                            className="repuesto-campo"
                                            required
                                            type="Text"
                                            name="cargorecibe"
                                            id="cargorecibe"
                                            label="Cargo de Quien Recibe la Actividad"
                                            value={this.state.cargorecibe}
                                            onChange={this.handleChange}
                                            fullWidth
                                            helperText={errors.cargorecibe}
                                            error={errors.cargorecibe ? true : false}
                                        />
                                    </div>
                                ) : this.state.metodoRecepcion === "firma" ? (
                                    <div className="div-firma-digital">
                                        <div className="firma-canvas-container">
                                            <canvas 
                                                ref={this.canvasRef}
                                                id="firma-digital-canvas" 
                                                className="firma-canvas"
                                                width="500" 
                                                height="200"
                                            />
                                        </div>
                                        <div className="div-firma-buttons">
                                            <Button 
                                                variant="outlined"
                                                onClick={this.clearSignature}
                                                className="btn-limpiar-firma"
                                            >
                                                Limpiar firma
                                            </Button>
                                            <Button 
                                                variant="contained"
                                                onClick={this.saveSignature}
                                                className="btn-guardar-firma"
                                            >
                                                Guardar firma
                                            </Button>
                                        </div>
                                        {/* Indicador de firma guardada */}
                                        {this.state.firmaGuardada && (
                                            <div className="firma-guardada-indicador" style={{ 
                                                color: '#20B90C', 
                                                marginTop: '10px', 
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center' 
                                            }}>
                                                <FaCheckCircle style={{ marginRight: '5px' }} />
                                                <span>Firma guardada correctamente</span>
                                            </div>
                                        )}
                                        <TextField 
                                            className="repuesto-campo mt-2"
                                            required
                                            type="Text"
                                            name="nombreFirmante"
                                            id="nombreFirmante"
                                            label="Nombre del firmante"
                                            value={this.state.nombreFirmante}
                                            onChange={this.handleChange}
                                            fullWidth
                                            helperText={errors.nombreFirmante}
                                            error={errors.nombreFirmante ? true : false}
                                        />
                                        <TextField 
                                            className="repuesto-campo"
                                            required
                                            type="Text"
                                            name="cargoFirmante"
                                            id="cargoFirmante"
                                            label="Cargo del firmante"
                                            value={this.state.cargoFirmante}
                                            onChange={this.handleChange}
                                            fullWidth
                                            helperText={errors.cargoFirmante}
                                            error={errors.cargoFirmante ? true : false}
                                        />
                                    </div>
                                ) : this.state.metodoRecepcion === "usuario" ? (
                                    <div className="div-seleccion-usuario">
                                        <FormControl className="formcontrol-nuevomto" fullWidth>
                                            <InputLabel>Usuario que recibe</InputLabel>
                                            <Select
                                                name="usuarioRecibe"
                                                id="usuarioRecibe"
                                                value={this.state.usuarioRecibe || ""}
                                                onChange={this.handleChange}
                                                fullWidth
                                            >
                                                <MenuItem value=""><em>Seleccione un usuario</em></MenuItem>
                                                {this.props.user.usuariosAsistenciales && this.props.user.usuariosAsistenciales.map(user => (
                                                    <MenuItem key={user.idUsuario} value={user.idUsuario}>
                                                        {user.nombre} {user.cargo ? `- ${user.cargo}` : ''}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <FormHelperText>{errors.usuarioRecibe}</FormHelperText>
                                        </FormControl>
                                        {this.state.usuarioRecibe && (
                                            <div className="div-usuario-info" style={{
                                                marginTop: '10px',
                                                padding: '10px',
                                                backgroundColor: '#f5f5f5',
                                                borderRadius: '4px'
                                            }}>
                                                <p className="usuario-info-nombre">
                                                    <strong>Nombre: </strong> 
                                                    {this.getUserInfo(this.state.usuarioRecibe, 'nombre')}
                                                </p>
                                                <p className="usuario-info-cargo">
                                                    <strong>Cargo: </strong> 
                                                    {this.getUserInfo(this.state.usuarioRecibe, 'cargo')}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div className="div-carga-imagen">
                                        <Button
                                            variant="contained"
                                            onClick={this.handleImageButtonClick}
                                            disabled={this.state.uploadingImage}
                                            className="btn-guardar-firma"
                                        >
                                            {this.state.uploadingImage ? 'Procesando...' : 'Cargar imagen de firma'}
                                        </Button>

                                        {this.state.imagenFirma && (
                                            <div className="div-preview-imagen">
                                                <img 
                                                    src={this.state.imagenFirma} 
                                                    alt="Vista previa de firma" 
                                                    className="preview-imagen-firma"
                                                    style={{ maxWidth: '100%', maxHeight: '200px', marginTop: '10px' }}
                                                />
                                            </div>
                                        )}
                                        <TextField 
                                            className="repuesto-campo mt-2"
                                            required
                                            type="Text"
                                            name="nombreFirmaImagen"
                                            id="nombreFirmaImagen"
                                            label="Nombre del firmante"
                                            value={this.state.nombreFirmaImagen}
                                            onChange={this.handleChange}
                                            fullWidth
                                            helperText={errors.nombreFirmaImagen}
                                            error={errors.nombreFirmaImagen ? true : false}
                                        />
                                        <TextField 
                                            className="repuesto-campo"
                                            required
                                            type="Text"
                                            name="cargoFirmaImagen"
                                            id="cargoFirmaImagen"
                                            label="Cargo del firmante"
                                            value={this.state.cargoFirmaImagen}
                                            onChange={this.handleChange}
                                            fullWidth
                                            helperText={errors.cargoFirmaImagen}
                                            error={errors.cargoFirmaImagen ? true : false}
                                        />
                                    </div>
                                )}
                            </div>
                        </form>  
                    </DialogContent>
                </div>)}

                <DialogActions>
                    <MyButton tip="Cerrar" onClick={this.handleClose}>
                        <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
                    </MyButton>
                    {loading ? (
                        <CircularProgress size={30} color="primary" className="login-progress" />
                    ) : (
                        this.state.aprobado && this.state.aprobado.mensaje ? (
                            <MyButton tip="Continuar" onClick={this.handleClose}>
                                <FaCheckCircle color="#20B90C" className="icon-formulario" />
                            </MyButton>
                        ) : (
                            <MyButton tip="Realizar Mantenimiento" onClick={this.handlePostMto}>
                                <FaPlus color="#03178C" className="icon-formulario" />
                            </MyButton>
                        )
                    )}
                </DialogActions>
            </Dialog>

            <Dialog
             open={this.state.openevidencia}
             onClose={this.handleCloseEvidencia}
             fullWidth
            maxWidth="sm"
            >
            <DialogTitle>Evidencia de la Solicitud</DialogTitle>
         
            <DialogContent>
            <div className='div-image-carga'>
            {this.state.imagenurl?(
              <img src={this.state.imagenurl} alt="Selected" style={{ maxWidth: '100%', maxHeight: '400px' }} />
            ):(
              <p>No hay Evidencia para Mostrar</p>
            )

            }
           
            </div>
            </DialogContent>

            <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleCloseEvidencia}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>
           
           </DialogActions>

            </Dialog>
    
    
        </div>
            
    )
  }
}

HistorialSolicitudCorrectivos.propTypes = {
  getSolicitudesCorrectivos: PropTypes.func.isRequired,
  getRepuestos: PropTypes.func.isRequired,
  getUsuariosAsistenciales: PropTypes.func.isRequired,
  postMtoCorrectivoConSolicitud: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  clearErrors: PropTypes.func.isRequired,
  UI: PropTypes.object.isRequired,
  deleteMtoCorrectivo: PropTypes.func.isRequired,
  equipoId: PropTypes.string.isRequired,
  clienteId: PropTypes.string.isRequired
};

const mapActionsToProps = {
  getSolicitudesCorrectivos,
  getRepuestos,
  getUsuariosAsistenciales,
  clearErrors,
  deleteMtoCorrectivo,
  postMtoCorrectivoConSolicitud
};

const mapStateToProps = (state) => ({
  user: state.user,
  data: state.data,
  UI: state.UI
});

export default connect(mapStateToProps, mapActionsToProps)(HistorialSolicitudCorrectivos);

