import React, { Component, Fragment } from 'react';
import './crearCasoTecno.css';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';


import TextField from '@material-ui/core/TextField';
import MyButton from '../../../utils/MyButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import CircularProgress from '@material-ui/core/CircularProgress';

import { FaArrowLeft, FaPlus, FaCalendarDay, FaCheck, FaCheckCircle, FaRegFileAlt, FaFileAlt } from "react-icons/fa";

import { clearErrors, createCasoTecno, getCasosTecnoEquipo } from '../../../redux/actions/dataactions';
import { Accordion, AccordionDetails, AccordionSummary, Link } from '@material-ui/core';
import moment from 'moment';

export class crearCasoTecnovigilancia extends Component {

  constructor() {
    super();
    this.state = {

      // Información del paciente
      tipoIdentificacion: "",
      numeroIdentificacion: "",
      sexo: "",
      edad: "",
      edadEn: "",
      eps: "",
      diagnosticoInicialPaciente: "",

      // Información del equipo
      nombreGenericoDispositivo: "",
      nombreComercialDispositivo: "",
      RegistroSanitario_PermisoComercializacion: "",
      activoFijo: "", //Alpha ?
      lote: "",
      modelo: "",
      referencia: "",
      serie: "",
      marca: "",
      importadorDistribuidor: "",
      areaAccidente: "",
      ubicacion: "",
      servicio: "",
      masDeUnUso: "",

      // Descripcion del evento o incidente adverso
      fechaReporte: moment().format('YYYY-MM-DD'),
      fechaDelEvento: "",
      momentoDeteccion: "",
      clasificacionCaso: "",
      descripcionCaso: "",
      desenlaceCaso: "",
      otro: "",

      // Gestión realizada
      causaCaso: "",
      accionTomada: "",
      reporteDistibuidor: "",
      fechaReporteDistribuidor: "",
      envioDistribuidor: "",
      fechaEnvioDistribuidor: "",
      disponibleEvaluacion: "",

      // Informacion del reportante
      nombreReportante: "",
      profesion: "",
      servicioPerteneciente: "",
      correoInstitucional: "",

      errors: {},
      aprobado: {},
      open: false
    };
  };
  static getDerivedStateFromProps(props, state) {
    const newState = {};

    if (props.UI.errors) {
      newState.errors = props.UI.errors;
    }

    if (props.UI.aprobado) {
      newState.aprobado = props.UI.aprobado;
    }

    return Object.keys(newState).length > 0 ? newState : null;
  }


  handleOpen = () => {
    this.setState({ open: true, aprobado: {}, errors: {} });
    this.setState({

      //Información de institución
      nombreInstitucion: this.props.data.cliente.nombre,

      //Información del equipo
      nombreGenericoDispositivo: this.props.equipo.nombre,
      nombreComercialDispositivo: this.props.equipo.nombre,
      RegistroSanitario_PermisoComercializacion: this.props.equipo.registroinvima,
      activoFijo: this.props.equipo.idinventario,
      modelo: this.props.equipo.modelo,
      marca: this.props.equipo.marca,
      serie: this.props.equipo.serie,
      importadorDistribuidor: this.props.equipo.proveedor,
      servicio: this.props.equipo.servicio,
      ubicacion: this.props.equipo.ubicacion,

      // Información reportante
      nombreReportante: this.props.usuario.nombre,
      profesion: this.props.usuario.tipouser,
      direccion: this.props.usuario.direccion,
      telefono: this.props.usuario.telefono,
      correoInstitucional: this.props.usuario.email,

    })
  };

  handleClose = () => {
    this.props.clearErrors();
    this.setState({ open: false, errors: {}, aprobado: {} });
    this.setState({
      // Información del paciente
      tipoIdentificacion: "",
      numeroIdentificacion: "",
      sexo: "",
      edad: "",
      edadEn: "",
      eps: "",
      diagnosticoInicialPaciente: "",

      // Información del equipo
      nombreGenericoDispositivo: "",
      nombreComercialDispositivo: "",
      RegistroSanitario_PermisoComercializacion: "",
      activoFijo: "", //Alpha ?
      lote: "",
      modelo: "",
      referencia: "",
      serie: "",
      marca: "",
      importadorDistribuidor: "",
      areaAccidente: "",
      ubicacion: "",
      servicio: "",
      masDeUnUso: "",

      // Descripcion del evento o incidente adverso
      fechaReporte: moment().format('YYYY-MM-DD'),
      fechaDelEvento: "",
      momentoDeteccion: "",
      clasificacionCaso: "",
      descripcionCaso: "",
      desenlaceCaso: "",
      otro: "",

      // Gestión realizada
      causaCaso: "",
      accionTomada: "",
      reporteDistibuidor: "",
      fechaReporteDistribuidor: "",
      envioDistribuidor: "",
      fechaEnvioDistribuidor: "",
      disponibleEvaluacion: "",

      // Informacion del reportante
      nombreReportante: "",
      profesion: "",
      servicioPerteneciente: "",
      correoInstitucional: "",
    })
    this.state.aprobado.mensaje && (
      this.props.getCasosTecnoEquipo(this.props.equipoId)
    )
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };



  handleSubmit = (event) => {
    event.preventDefault();

    const newCaso = {
      cliente:this.props.clienteId,
      tipoIdentificacion: this.state.tipoIdentificacion,
      numeroIdentificacion: this.state.numeroIdentificacion,
      sexo: this.state.sexo,
      edad: this.state.edad,
      edadEn: this.state.edadEn,
      eps: this.state.eps,
      diagnosticoInicialPaciente: this.state.diagnosticoInicialPaciente,

      nombreGenericoDispositivo: this.state.nombreGenericoDispositivo,
      nombreComercialDispositivo: this.state.nombreComercialDispositivo,
      RegistroSanitario_PermisoComercializacion: this.state.RegistroSanitario_PermisoComercializacion,
      activoFijo: this.state.activoFijo, //Alpha ?
      lote: this.state.lote,
      modelo: this.state.modelo,
      referencia: this.state.referencia,
      serie: this.state.serie,
      marca: this.state.marca,
      importadorDistribuidor: this.state.importadorDistribuidor,
      areaAccidente: this.state.servicio + "/" + this.state.ubicacion,
      masDeUnUso: this.state.masDeUnUso,

      // Descripcion del evento o incidente adverso
      fechaReporte: moment(this.state.fechaReporte).format('DD/MM/YYYY'),
      fechaDelEvento: moment(this.state.fechaDelEvento).format('DD/MM/YYYY'),
      momentoDeteccion: this.state.momentoDeteccion,
      clasificacionCaso: this.state.clasificacionCaso,
      descripcionCaso: this.state.descripcionCaso,
      desenlaceCaso: this.state.desenlaceCaso,
      otro: this.state.otro,

      causaCaso: this.state.causaCaso,
      accionTomada: this.state.accionTomada,
      reporteDistibuidor: this.state.reporteDistibuidor,
      fechaReporteDistribuidor: moment(this.state.fechaReporteDistribuidor).format('DD/MM/YYYY'),
      envioDistribuidor: this.state.envioDistribuidor,
      fechaEnvioDistribuidor: moment(this.state.fechaEnvioDistribuidor).format('DD/MM/YYYY'),
      disponibleEvaluacion: this.state.disponibleEvaluacion,

      //Informacion del reportante
      nombreReportante: this.state.nombreReportante,
      profesion: this.state.profesion,
      direccion: this.state.direccion,
      telefono: this.state.telefono,
      servicioPerteneciente: this.state.servicioPerteneciente,
      correoInstitucional: this.state.correoInstitucional,
      estadoReporte: "A"
    };
    this.props.usuario.tipouser == "Asistencial" ? (
      //Aqui se postea al cliente
      this.props.createCasoTecno(newCaso, this.props.usuario.idprestador, this.props.equipoId,)
    ) : (
      //Si no es asistencial es ingeniería, o técnico
      this.props.createCasoTecno(newCaso, this.props.clienteId, this.props.equipoId,)
    )
  }

  render() {
    const { UI: { loading } } = this.props;
    const { errors } = this.state;

    return (
      <div>
        <Fragment>
          <MyButton tip="Registrar caso de tecnovigilancia" onClick={this.handleOpen}>
            <FaFileAlt gFileAlt color="#03178C" className="icon-crearcliente" />
          </MyButton>

          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            fullWidth
            maxWidth="sm"
          >
            {this.state.aprobado.mensaje ? (

              <div className='div-aprobado'>
                <div className='div-icono-aprobado'>
                  <FaCheck color="#ffffff" className='icono-aprobado' />
                </div>
                <h2 className='text-aprobado'>¡Se ha registrado exitosamente el caso!</h2>

                {(this.state.clasificacionCaso == "Evento adverso serio" || this.state.clasificacionCaso == "Incidente adverso serio") ? (
                  <div className='mensaje_casos'>
                    <p>
                      Este caso está registrado como evento/incidente adverso serio. <br />
                      Recuerde que tiene menos de <strong>72 horas desde la ocurrencia del caso</strong> para completar y subir el {' '}
                      <a href="https://www.saludcapital.gov.co/SectorBelleza/Galeria%20de%20Descargas/Acreditaci%C3%B3n/Est%C3%A1ndares%20acreditaci%C3%B3n%20est%C3%A9tica/REPORTE%20EVENTOS%20SERIOS%20FOREIA001.pdf" target="_blank" rel="noopener noreferrer">
                        formato FOREIA001
                      </a>{' '}
                      a la plataforma del INVIMA.
                    </p>
                  </div>
                ) : (
                  <div className='mensaje_casos'>
                    <p>
                      Este caso está registrado como evento/incidente adverso no serio. <br />
                      Recuerde que este tipo de casos <strong>deben ser registrados en el </strong> {' '}
                      <a href="https://ids.gov.co/2021/Medicamentos/RETEIM-002-reporte_masivo_trimestral.xlsx" target="_blank" rel="noopener noreferrer">
                        formato RETEIM-002
                      </a>{' '}
                      <strong>por el ingeniero encargado </strong> y cargarlo en el trimestre del año correspondiente a la plataforma del INVIMA.
                    </p>
                  </div>)

                }

              </div>

            ) : (
              <div>
                <DialogTitle>Registrar caso de tecnovigilancia</DialogTitle>

                <DialogContent>
                  <form>


                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <h4 className="text-title-accorion">Información del paciente (Opcional) </h4>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="flex-accordion">
                          <div className="input_divido">
                            <div className="input_divido_children">
                              <FormControl className="formcontrol-nuevocliente" error={errors.tipoIdentificacion ? true : false}>
                                <InputLabel>Tipo de documento</InputLabel>
                                <Select
                                  required
                                  name="tipoIdentificacion"
                                  id="tipoIdentificacion"
                                  value={this.state.tipoIdentificacion}
                                  onChange={this.handleChange}
                                  fullWidth
                                >
                                  <MenuItem value="">
                                    <em></em>
                                  </MenuItem>
                                  <MenuItem value="CC">Cédula de Ciudadanía</MenuItem>
                                  <MenuItem value="TI">Tarjeta de Identidad</MenuItem>
                                  <MenuItem value="RC">Registro Civil</MenuItem>
                                  <MenuItem value="PS">Pasaporte</MenuItem>
                                  <MenuItem value="CE">Cédula de Extranjería</MenuItem>
                                  <MenuItem value="MI">Menor sin Identificación</MenuItem>
                                  <MenuItem value="AI">Adulto sin Identificación</MenuItem>
                                  <MenuItem value="HC">Historia Clinica</MenuItem>

                                </Select>
                                <FormHelperText>{errors.tipoIdentificacion}</FormHelperText>
                              </FormControl>
                            </div>
                            <div className="input_divido_children">
                              <TextField className="login-input-email"
                                required
                                type="number"
                                name="numeroIdentificacion"
                                id="numeroIdentificacion"
                                label="Número de identificación"
                                value={this.state.numeroIdentificacion}
                                onChange={this.handleChange}
                                fullWidth
                                helperText={errors.numeroIdentificacion}
                                error={errors.numeroIdentificacion ? true : false}
                              />
                            </div>
                          </div>

                          <div className="input_divido">
                            <div className="input_divido_children">
                              <FormControl className="formcontrol-nuevocliente" error={errors.sexo ? true : false}>
                                <InputLabel>Sexo</InputLabel>
                                <Select
                                  required
                                  name="sexo"
                                  id="sexo"
                                  value={this.state.sexo}
                                  onChange={this.handleChange}
                                  fullWidth
                                >
                                  <MenuItem value="">
                                    <em></em>
                                  </MenuItem>
                                  <MenuItem value="Femenino">Femenino</MenuItem>
                                  <MenuItem value="Masculino">Masculino</MenuItem>
                                  {//<MenuItem value="Sin dato">39 tipos de Gay</MenuItem>
                                  }
                                  <MenuItem value="Sin dato">Sin dato</MenuItem>
                                </Select>
                                <FormHelperText>{errors.sexo}</FormHelperText>
                              </FormControl>
                            </div>
                          </div>


                          <div className="input_divido">
                            <div className="input_divido_children">
                              <TextField className="login-input-email"
                                required
                                type="number"
                                name="edad"
                                id="edad"
                                label="Edad"
                                value={this.state.edad}
                                onChange={this.handleChange}
                                fullWidth
                                helperText={errors.edad}
                                error={errors.edad ? true : false}
                              />
                            </div>
                            <div className="input_divido_children">
                              <FormControl className="formcontrol-nuevocliente" error={errors.edadEn ? true : false}>
                                <InputLabel>Edad de la persona en</InputLabel>
                                <Select
                                  required
                                  name="edadEn"
                                  id="edadEn"
                                  value={this.state.edadEn}
                                  onChange={this.handleChange}
                                  fullWidth
                                >
                                  <MenuItem value="">
                                    <em></em>
                                  </MenuItem>
                                  <MenuItem value="días">Días</MenuItem>
                                  <MenuItem value="semanas">Semanas</MenuItem>
                                  <MenuItem value="meses">Meses</MenuItem>
                                  <MenuItem value="años">Años</MenuItem>
                                </Select>
                                <FormHelperText>{errors.edadEn}</FormHelperText>
                              </FormControl>
                            </div>
                          </div>

                          <TextField className="login-input-email"
                            required
                            type="text"
                            name="eps"
                            id="eps"
                            label="EPS del paciente"
                            value={this.state.eps}
                            onChange={this.handleChange}
                            fullWidth
                            helperText={errors.eps}
                            error={errors.eps ? true : false}
                          />

                          <TextField className="login-input-email"
                            required
                            type="text"
                            name="diagnosticoInicialPaciente"
                            id="diagnosticoInicialPaciente"
                            label="Diagnóstico inicial del paciente"
                            value={this.state.diagnosticoInicialPaciente}
                            onChange={this.handleChange}
                            fullWidth
                            helperText={errors.diagnosticoInicialPaciente}
                            error={errors.diagnosticoInicialPaciente ? true : false}
                          />
                        </div>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <h4 className="text-title-accorion">Información del equipo</h4>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="flex-accordion">

                          <TextField className="login-input-email"
                            required
                            type="text"
                            name="nombreGenericoDispositivo"
                            id="nombreGenericoDispositivo"
                            label="Nombre genérico del equipo"
                            value={this.state.nombreGenericoDispositivo}
                            onChange={this.handleChange}
                            fullWidth
                            helperText={errors.nombreGenericoDispositivo}
                            error={errors.nombreGenericoDispositivo ? true : false}
                            disabled
                          />

                          <TextField className="login-input-email"
                            required
                            type="text"
                            name="nombreComercialDispositivo"
                            id="nombreComercialDispositivo"
                            label="Nombre comercial del equipo"
                            value={this.state.nombreComercialDispositivo}
                            onChange={this.handleChange}
                            fullWidth
                            helperText={errors.nombreComercialDispositivo}
                            error={errors.nombreComercialDispositivo ? true : false}

                          />


                          <div className="input_divido">
                            <div className="input_divido_children">
                              <TextField className="login-input-email"
                                required
                                type="text"
                                name="RegistroSanitario_PermisoComercializacion"
                                id="RegistroSanitario_PermisoComercializacion"
                                label="Registro Invima"
                                value={this.state.RegistroSanitario_PermisoComercializacion}
                                onChange={this.handleChange}
                                fullWidth
                                helperText={errors.RegistroSanitario_PermisoComercializacion}
                                error={errors.RegistroSanitario_PermisoComercializacion ? true : false}
                                disabled
                              />
                            </div>
                            <div className="input_divido_children">
                              <TextField className="login-input-email"
                                required
                                type="text"
                                name="activoFijo"
                                id="activoFijo"
                                label="Activo fijo del equipo"
                                value={this.state.activoFijo}
                                onChange={this.handleChange}
                                fullWidth
                                helperText={errors.activoFijo}
                                error={errors.activoFijo ? true : false}
                                disabled
                              />
                            </div>
                          </div>

                          <div className="input_divido">
                            <div className="input_divido_children">
                              <TextField className="login-input-email"

                                type="text"
                                name="lote"
                                id="lote"
                                label="Lote"
                                value={this.state.lote}
                                onChange={this.handleChange}
                                fullWidth
                                helperText={errors.lote}
                                error={errors.lote ? true : false}
                              />
                            </div>
                            <div className="input_divido_children">
                              <TextField className="login-input-email"
                                required
                                type="text"
                                name="modelo"
                                id="modelo"
                                label="Modelo"
                                value={this.state.modelo}
                                onChange={this.handleChange}
                                fullWidth
                                helperText={errors.modelo}
                                error={errors.modelo ? true : false}
                                disabled
                              />
                            </div>
                          </div>


                          <div className="input_divido">
                            <div className="input_divido_children">
                              <TextField className="login-input-email"

                                type="text"
                                name="referencia"
                                id="referencia"
                                label="Referencia"
                                value={this.state.referencia}
                                onChange={this.handleChange}
                                fullWidth
                                helperText={errors.referencia}
                                error={errors.referencia ? true : false}
                              />
                            </div>
                            <div className="input_divido_children">
                              <TextField className="login-input-email"
                                required
                                type="text"
                                name="serie"
                                id="serie"
                                label="Serie"
                                value={this.state.serie}
                                onChange={this.handleChange}
                                fullWidth
                                helperText={errors.serie}
                                error={errors.serie ? true : false}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="input_divido">
                            <div className="input_divido_children">
                              <TextField className="login-input-email"
                                required
                                type="text"
                                name="marca"
                                id="marca"
                                label="Razón social del fabricante"
                                value={this.state.marca}
                                onChange={this.handleChange}
                                fullWidth
                                helperText={errors.marca}
                                disabled
                                error={errors.marca ? true : false}
                              />
                            </div>
                            <div className="input_divido_children">
                              <TextField className="login-input-email"
                                required
                                type="text"
                                name="importadorDistribuidor"
                                id="importadorDistribuidor"
                                label="Importador/distribuidor"
                                value={this.state.importadorDistribuidor}
                                onChange={this.handleChange}
                                fullWidth
                                helperText={errors.importadorDistribuidor}
                                error={errors.importadorDistribuidor ? true : false}
                                disabled
                              />
                            </div>
                          </div>

                          <div className="input_divido">
                            <div className="input_divido_children">
                              <TextField className="login-input-email"
                                required
                                type="text"
                                name="servicio"
                                id="servicio"
                                label="Servicio"
                                value={this.state.servicio}
                                onChange={this.handleChange}
                                fullWidth
                                helperText={errors.servicio}
                                error={errors.servicio ? true : false}
                              />
                            </div>
                            <div className="input_divido_children">
                              <TextField className="login-input-email"
                                required
                                type="text"
                                name="ubicacion"
                                id="ubicacion"
                                label="Ubicación"
                                value={this.state.ubicacion}
                                onChange={this.handleChange}
                                fullWidth
                                helperText={errors.ubicacion}
                                error={errors.ubicacion ? true : false}
                              />
                            </div>
                          </div>

                          <FormControl className="formcontrol-nuevocliente" error={errors.masDeUnUso ? true : false}>
                            <InputLabel>¿Se ha usado más de una vez?</InputLabel>
                            <Select
                              required
                              name="masDeUnUso"
                              id="masDeUnUso"
                              value={this.state.masDeUnUso}
                              onChange={this.handleChange}
                              fullWidth
                            >
                              <MenuItem value="">
                                <em></em>
                              </MenuItem>
                              <MenuItem value="Si">Sí</MenuItem>
                              <MenuItem value="No">No</MenuItem>
                            </Select>
                            <FormHelperText>{errors.masDeUnUso}</FormHelperText>
                          </FormControl>
                        </div>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <h4 className="text-title-accorion">Clasificación del caso</h4>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="flex-accordion">

                          <div className="input_divido">
                            <div className="input_divido_children">
                              <TextField className="login-input-email"
                                required
                                type="date"
                                name="fechaDelEvento"
                                id="fechaDelEvento"
                                label="Fecha de ocurrencia del evento"
                                value={this.state.fechaDelEvento}
                                onChange={this.handleChange}
                                fullWidth
                                helperText={errors.fechaDelEvento}
                                error={errors.fechaDelEvento ? true : false}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  max: moment().format('YYYY-MM-DD') // Establece la fecha máxima como la fecha actual
                                }}
                              />
                            </div>
                            <div className="input_divido_children">
                              <TextField className="login-input-email"
                                required
                                type="date"
                                name="fechaReporte"
                                id="fechaReporte"
                                label="Fecha del reporte"
                                value={this.state.fechaReporte}
                                onChange={this.handleChange}
                                fullWidth
                                helperText={errors.fechaReporte}
                                error={errors.fechaReporte ? true : false}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  max: moment().format('YYYY-MM-DD') // Establece la fecha máxima como la fecha actual
                                }}
                                disabled
                              />
                            </div>
                          </div>

                          <div className="input_divido">
                            <div className="input_divido_children">
                              <FormControl className="formcontrol-nuevocliente" error={errors.momentoDeteccion ? true : false}>
                                <InputLabel>Detección del caso</InputLabel>
                                <Select
                                  required
                                  name="momentoDeteccion"
                                  id="momentoDeteccion"
                                  value={this.state.momentoDeteccion}
                                  onChange={this.handleChange}
                                  fullWidth
                                >
                                  <MenuItem value="">
                                    <em></em>
                                  </MenuItem>
                                  <MenuItem value="Antes del uso del DM">Antes de utilizarlo</MenuItem>
                                  <MenuItem value="Durante el uso del DM">Durante el uso</MenuItem>
                                  <MenuItem value="Durante el uso del DM">Después de utilizarlo</MenuItem>

                                </Select>
                                <FormHelperText>{errors.momentoDeteccion}</FormHelperText>
                              </FormControl>
                            </div>
                            <div className="input_divido_children">
                              <FormControl className="formcontrol-nuevocliente" error={errors.clasificacionCaso ? true : false}>
                                <InputLabel>Clasificación</InputLabel>
                                <Select
                                  required
                                  name="clasificacionCaso"
                                  id="clasificacionCaso"
                                  value={this.state.clasificacionCaso}
                                  onChange={this.handleChange}
                                  fullWidth
                                >
                                  <MenuItem value="">
                                    <em></em>
                                  </MenuItem>
                                  <MenuItem value="Evento adverso serio">Evento adverso serio</MenuItem>
                                  <MenuItem value="Evento adverso no serio">Evento adverso no serio</MenuItem>
                                  <MenuItem value="Incidente adverso serio">Incidente adverso serio</MenuItem>
                                  <MenuItem value="Incidente adverso no serio">Incidente adverso no serio</MenuItem>

                                </Select>
                                <FormHelperText>{errors.clasificacionCaso}</FormHelperText>
                              </FormControl>
                            </div>
                          </div>

                          <FormControl className="formcontrol-nuevocliente" error={errors.desenlaceCaso ? true : false}>
                            <InputLabel>Desenlace del caso</InputLabel>
                            <Select
                              required
                              name="desenlaceCaso"
                              id="desenlaceCaso"
                              value={this.state.desenlaceCaso}
                              onChange={this.handleChange}
                              fullWidth
                            >
                              <MenuItem value="">
                                <em></em>
                              </MenuItem>
                              <MenuItem value="Murio">Murio</MenuItem>
                              <MenuItem value="Daño de una función o estructura corporal">Daño de una función o estructura corporal</MenuItem>
                              <MenuItem value="Enfermedad o daño que amenace la vida">Enfermedad o daño que amenace la vida</MenuItem>
                              <MenuItem value="Requiere intervención médica o quirúrgica, para prevenir un daño permanente de una estructura o función corporal">Requiere intervención médica o quirúrgica, para prevenir un daño permanente de una estructura o función corporal</MenuItem>
                              <MenuItem value="Hospitalización inicial o prolongada">Hospitalización inicial o prolongada</MenuItem>
                              <MenuItem value="No hubo daño">No hubo daño</MenuItem>
                              <MenuItem value="Otro">Otro</MenuItem>
                            </Select>
                            <FormHelperText>{errors.desenlaceCaso}</FormHelperText>
                          </FormControl>

                          <TextField className="login-input-email"
                            required
                            type="text"
                            name="otro"
                            id="otro"
                            label="¿Otro? Especifique"
                            value={this.state.otro}
                            onChange={this.handleChange}
                            fullWidth
                            helperText={errors.otro}
                            error={errors.otro ? true : false}
                            disabled={this.state.desenlaceCaso == "Otro" ? false : true}
                          />
                          <TextField className="login-input-email"
                            required
                            type="text"
                            name="descripcionCaso"
                            id="descripcionCaso"
                            label="Descripción del caso"
                            value={this.state.descripcionCaso}
                            onChange={this.handleChange}
                            fullWidth
                            helperText={errors.descripcionCaso}
                            error={errors.descripcionCaso ? true : false}
                          />
                        </div>

                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <h4 className="text-title-accorion">Gestion Realizada</h4>
                      </AccordionSummary>

                      <AccordionDetails>
                        <div className="flex-accordion">
                          <FormControl className="formcontrol-nuevocliente" error={errors.causaCaso ? true : false}>
                            <InputLabel>Causa probable del caso</InputLabel>
                            <Select
                              required
                              name="causaCaso"
                              id="causaCaso"
                              value={this.state.causaCaso}
                              onChange={this.handleChange}
                              fullWidth
                            >
                              <MenuItem value="">
                                <em></em>
                              </MenuItem>
                              <MenuItem value="Uso anormal">Uso anormal</MenuItem>
                              <MenuItem value="Respuesta fisiológica anormal o inesperada">Respuesta fisiológica anormal o inesperada</MenuItem>
                              <MenuItem value="Falla en la alarma">Falla en la alarma</MenuItem>
                              <MenuItem value="Uso de material biológico">Uso de material biológico</MenuItem>
                              <MenuItem value="Calibración">Calibración</MenuItem>
                              <MenuItem value="Hardware del computador">Hardware del computador</MenuItem>
                              <MenuItem value="Contaminación durante la producción">Contaminación durante la producción</MenuItem>
                              <MenuItem value="Contaminación post-producción">Contaminación post-producción</MenuItem>
                              <MenuItem value="Diseño">Diseño</MenuItem>
                              <MenuItem value="Desconexión">Desconexión</MenuItem>
                              <MenuItem value="Componente eléctrico">Componente eléctrico</MenuItem>
                              <MenuItem value="Circuito eléctrico">Circuito eléctrico</MenuItem>
                              <MenuItem value="Contacto eléctrico">Contacto eléctrico</MenuItem>
                              <MenuItem value="Interferencia Electromagnética IEM">Interferencia Electromagnética IEM</MenuItem>
                              <MenuItem value="Fecha de expiración">Fecha de expiración</MenuItem>
                              <MenuItem value="Falso Negativo">Falso Negativo</MenuItem>
                              <MenuItem value="Falso positivo">Falso positivo</MenuItem>
                              <MenuItem value="Resultado falso de la prueba">Resultado falso de la prueba</MenuItem>
                              <MenuItem value="Falla en el dispositivo implantable">Falla en el dispositivo implantable</MenuItem>
                              <MenuItem value="Ambiente Inapropiado">Ambiente Inapropiado</MenuItem>
                              <MenuItem value="Incompatibilidad">Incompatibilidad</MenuItem>
                              <MenuItem value="Instrucciones para uso y etiquetado">Instrucciones para uso y etiquetado</MenuItem>
                              <MenuItem value="Escape/ sellado">Escape/ sellado</MenuItem>
                              <MenuItem value="Mantenimiento">Mantenimiento</MenuItem>
                              <MenuItem value="Fabricación">Fabricación</MenuItem>
                              <MenuItem value="Material">Material</MenuItem>
                              <MenuItem value="Componentes Mecánicos">Componentes Mecánicos</MenuItem>
                              <MenuItem value="Condiciones no higiénicas">Condiciones no higiénicas</MenuItem>
                              <MenuItem value="No relacionado con el dispositivo">No relacionado con el dispositivo</MenuItem>
                              <MenuItem value="Otros">Otros</MenuItem>
                              <MenuItem value="Empaque">Empaque</MenuItem>
                              <MenuItem value="Anatomía/Fisiología del paciente">Anatomía/Fisiología del paciente</MenuItem>
                              <MenuItem value="Condición del paciente">Condición del paciente</MenuItem>
                              <MenuItem value="Fuente de energía">Fuente de energía</MenuItem>
                              <MenuItem value="Medidas de protección">Medidas de protección</MenuItem>
                              <MenuItem value="Aseguramiento  de la calidad en la institución para la atención en salud">Aseguramiento  de la calidad en la institución para la atención en salud</MenuItem>
                              <MenuItem value="Radiación">Radiación</MenuItem>
                              <MenuItem value="Software">Software</MenuItem>
                              <MenuItem value="Esterilización/desinfección/ limpieza">Esterilización/desinfección/ limpieza</MenuItem>
                              <MenuItem value="Condiciones de almacenamiento">Condiciones de almacenamiento</MenuItem>
                              <MenuItem value="Alteración/falsificación/ sabotaje">Alteración/falsificación/ sabotaje</MenuItem>
                              <MenuItem value="Entrenamiento">Entrenamiento</MenuItem>
                              <MenuItem value="Transporte y entrega">Transporte y entrega</MenuItem>
                              <MenuItem value="Sin identificar">Sin identificar</MenuItem>
                              <MenuItem value="Capacidad de Uso">Capacidad de Uso</MenuItem>
                              <MenuItem value="Error de Uso">Error de Uso</MenuItem>
                              <MenuItem value="Desgaste">Desgaste</MenuItem>
                            </Select>
                            <FormHelperText>{errors.causaCaso}</FormHelperText>

                          </FormControl>

                          <TextField className="login-input-email"
                            required
                            type="text"
                            name="accionTomada"
                            id="accionTomada"
                            label="Acciones correctivas y preventivas iniciadas"
                            value={this.state.accionTomada}
                            onChange={this.handleChange}
                            fullWidth
                            helperText={errors.accionTomada}
                            error={errors.accionTomada ? true : false}
                          />

                          <div className="input_divido">
                            <div className="input_divido_children">
                              <FormControl className="formcontrol-nuevocliente" error={errors.reporteDistibuidor ? true : false}>
                                <InputLabel>¿Reportó al distribuidor?</InputLabel>
                                <Select
                                  required
                                  name="reporteDistibuidor"
                                  id="reporteDistibuidor"
                                  value={this.state.reporteDistibuidor}
                                  onChange={this.handleChange}
                                  fullWidth
                                >
                                  <MenuItem value="">
                                    <em></em>
                                  </MenuItem>
                                  <MenuItem value="Si">Sí</MenuItem>
                                  <MenuItem value="No">No</MenuItem>
                                </Select>
                                <FormHelperText>{errors.reporteDistibuidor}</FormHelperText>
                              </FormControl>

                            </div>
                            <div className="input_divido_children">
                              <TextField className="login-input-email"
                                type="date"
                                name="fechaReporteDistribuidor"
                                id="fechaReporteDistribuidor"
                                label="Fecha de reporte al distribuidor"
                                value={this.state.fechaReporteDistribuidor}
                                onChange={this.handleChange}
                                fullWidth
                                helperText={errors.fechaReporteDistribuidor}
                                error={errors.fechaReporteDistribuidor ? true : false}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  max: moment().format('YYYY-MM-DD') // Establece la fecha máxima como la fecha actual
                                }}
                                disabled={this.state.reporteDistibuidor == "Si" ? false : true}
                              />
                            </div>
                          </div>

                          <div className="input_divido">
                            <div className="input_divido_children">
                              <FormControl className="formcontrol-nuevocliente" error={errors.envioDistribuidor ? true : false}>
                                <InputLabel>¿Se envió al distribuidor?</InputLabel>
                                <Select
                                  required
                                  name="envioDistribuidor"
                                  id="envioDistribuidor"
                                  value={this.state.envioDistribuidor}
                                  onChange={this.handleChange}
                                  fullWidth
                                >
                                  <MenuItem value="">
                                    <em></em>
                                  </MenuItem>
                                  <MenuItem value="Si">Sí</MenuItem>
                                  <MenuItem value="No">No</MenuItem>
                                </Select>
                                <FormHelperText>{errors.envioDistribuidor}</FormHelperText>
                              </FormControl>

                            </div>
                            <div className="input_divido_children">
                              <TextField className="login-input-email"
                                type="date"
                                name="fechaEnvioDistribuidor"
                                id="fechaEnvioDistribuidor"
                                label="Fecha de envio al distribuidor"
                                value={this.state.fechaEnvioDistribuidor}
                                onChange={this.handleChange}
                                fullWidth
                                helperText={errors.fechaEnvioDistribuidor}
                                error={errors.fechaEnvioDistribuidor ? true : false}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  max: moment().format('YYYY-MM-DD') // Establece la fecha máxima como la fecha actual
                                }}
                                disabled={this.state.envioDistribuidor == "Si" ? false : true}
                              />
                            </div>
                          </div>

                          <FormControl className="formcontrol-nuevocliente" error={errors.disponibleEvaluacion ? true : false}>
                            <InputLabel>¿Disponible para evaluación?</InputLabel>
                            <Select
                              required
                              name="disponibleEvaluacion"
                              id="disponibleEvaluacion"
                              value={this.state.disponibleEvaluacion}
                              onChange={this.handleChange}
                              fullWidth
                            >
                              <MenuItem value="">
                                <em></em>
                              </MenuItem>
                              <MenuItem value="Si">Sí</MenuItem>
                              <MenuItem value="No">No</MenuItem>
                            </Select>
                            <FormHelperText>{errors.disponibleEvaluacion}</FormHelperText>
                          </FormControl>

                        </div>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <h4 className="text-title-accorion">Información del reportante</h4>
                      </AccordionSummary>

                      <AccordionDetails>
                        <div className="flex-accordion">

                          <TextField className="login-input-email"
                            required
                            type="text"
                            name="nombreReportante"
                            id="nombreReportante"
                            label="Nombres y apellidos completos del reportante"
                            value={this.state.nombreReportante}
                            onChange={this.handleChange}
                            fullWidth
                            helperText={errors.nombreReportante}
                            error={errors.nombreReportante ? true : false}
                          />
                          <div className="input_divido">
                            <div className="input_divido_children">
                              <TextField className="login-input-email"
                                required
                                type="text"
                                name="profesion"
                                id="profesion"
                                label="Profesión"
                                value={this.state.profesion}
                                onChange={this.handleChange}
                                fullWidth
                                helperText={errors.profesion}
                                error={errors.profesion ? true : false}
                              />
                            </div>
                            <div className="input_divido_children">
                              <TextField className="login-input-email"
                                required
                                type="text"
                                name="servicioPerteneciente"
                                id="servicioPerteneciente"
                                label="Área o servicio al que pertenece"
                                value={this.state.servicioPerteneciente}
                                onChange={this.handleChange}
                                fullWidth
                                helperText={errors.servicioPerteneciente}
                                error={errors.servicioPerteneciente ? true : false}
                              />
                            </div>
                          </div>



                          <div className="input_divido">
                            <div className="input_divido_children">
                              <TextField className="login-input-email"
                                type="text"
                                name="direccion"
                                id="direccion"
                                label="Dirección"
                                value={this.state.direccion}
                                onChange={this.handleChange}
                                fullWidth
                                helperText={errors.direccion}
                                error={errors.direccion ? true : false}
                              />
                            </div>
                            <div className="input_divido_children">
                              <TextField className="login-input-email"
                                type="text"
                                name="telefono"
                                id="telefono"
                                label="Teléfono"
                                value={this.state.telefono}
                                onChange={this.handleChange}
                                fullWidth
                                helperText={errors.telefono}
                                error={errors.telefono ? true : false}
                              />
                            </div>
                          </div>


                          <TextField className="login-input-email"
                            type="text"
                            name="correoInstitucional"
                            id="correoInstitucional"
                            label="Correo institucional"
                            value={this.state.correoInstitucional}
                            onChange={this.handleChange}
                            fullWidth
                            helperText={errors.correoInstitucional}
                            error={errors.correoInstitucional ? true : false}
                          />
                        </div>
                      </AccordionDetails>
                    </Accordion>

                    {Object.keys(errors).length > 0 && (
                      <p>Por favor, diligencie todos los campos mínimamente obligatorios para crear un caso</p>
                    )}

                  </form>
                </DialogContent>
              </div>
            )}

            <DialogActions>
              <MyButton tip="Cerrar" onClick={this.handleClose}>
                <FaArrowLeft color="#D3D7DC" className="icon-formulario" />
              </MyButton>

              {loading ? (
                <CircularProgress size={30} color="primary" className="login-progress" />
              ) : (
                this.state.aprobado.mensaje ? (
                  <MyButton tip="Continuar" onClick={this.handleClose}>
                    <FaCheckCircle color="#20B90C" className="icon-formulario" />
                  </MyButton>
                ) : (
                  <MyButton tip="Registrar caso" onClick={this.handleSubmit}>
                    <FaPlus color="#03178C" className="icon-formulario" />
                  </MyButton>
                )
              )}

            </DialogActions>

          </Dialog>

        </Fragment>

      </div>
    )
  }
}

crearCasoTecnovigilancia.propTypes = {
  clearErrors: PropTypes.func.isRequired,
  getCasosTecnoEquipo: PropTypes.func.isRequired,
  createCasoTecno: PropTypes.func.isRequired,
  UI: PropTypes.object.isRequired,
};

const mapActionsToProps = {
  clearErrors,
  createCasoTecno,
  getCasosTecnoEquipo
}
const mapStateToProps = (state) => ({
  UI: state.UI,
  equipo: state.data.equipo.credentials,
  data: state.data,
  usuario: state.user.credentials
});

export default connect(mapStateToProps, mapActionsToProps)(crearCasoTecnovigilancia)