import React from 'react';
import { Document, Page, View, Text, Image, StyleSheet } from '@react-pdf/renderer';
import alphalogo from '../../../utils/alphahorizontal.png';

// Generate styles with customizable colors
const createStyles = (primaryColor = '#005695', secondaryColor = '#0067b2') => {
  const colors = {
    primary: primaryColor,
    secondary: secondaryColor,
    gray: {
      50: '#F9FAFB',
      100: '#F3F4F6',
      200: '#E5E7EB',
      300: '#D1D5DB',
      800: '#1F2937',
    },
    white: '#FFFFFF'
  };

  return StyleSheet.create({
    page: {
      backgroundColor: colors.white,
      padding: 20,
      fontFamily: 'Helvetica',
    },
    header: {
      backgroundColor: colors.white,
      padding: 15,
      borderRadius: 8,
      marginBottom: 15,
      borderWidth: 1,
      borderColor: colors.gray[200],
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 10,
    },
    headerTitle: {
      backgroundColor: colors.primary,
      padding: 6,
      borderRadius: 4,
    },
    headerTitleText: {
      color: colors.white,
      fontSize: 11,
      fontWeight: 'bold',
      textAlign: 'center',
    },
    headerInfo: {
      flexDirection: 'column',
      gap: 4,
    },
    headerInfoText: {
      fontSize: 7,
      color: colors.gray[800],
    },
    section: {
      marginBottom: 6,
      borderWidth: 1,
      borderColor: colors.gray[200],
      borderRadius: 4,
      padding: 4,
    },
    sectionTitle: {
      backgroundColor: colors.primary,
      padding: 4,
      borderRadius: 4,
      marginBottom: 3,
    },
    sectionTitleText: {
      color: colors.white,
      fontSize: 9,
      fontWeight: 'bold',
    },
    row: {
      flexDirection: 'row',
      marginBottom: 2,
    },
    label: {
      width: '40%',
      fontSize: 7,
      fontWeight: 'bold',
      color: colors.gray[800],
    },
    value: {
      flex: 1,
      fontSize: 7,
      color: colors.gray[800],
    },
    image: {
      width: 50,
      height: 50,
      borderRadius: 4,
      objectFit: 'cover',
      borderWidth: 1,
      borderColor: colors.gray[200],
      backgroundColor: colors.gray[50],
      marginRight: 6,
    },
    equipmentContainer: {
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
    equipmentInfoContainer: {
      flex: 1,
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginLeft: 4,
    },
    equipmentInfoItem: {
      width: '50%',
      flexDirection: 'row',
      marginBottom: 2,
    },
    footer: {
      position: 'absolute',
      bottom: 15,
      left: 20,
      right: 20,
      borderTopWidth: 1,
      borderColor: colors.gray[200],
      paddingTop: 3,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    footerLogo: {
      width: 100,
      height: 20,
      objectFit: 'contain',
    },
    footerText: {
      fontSize: 7,
      color: colors.gray[800],
    }
  });
};

const ReporteTecnoDocuPDF = ({ caso }) => {
  if (!caso) {
    return (
      <Document>
        <Page size="A4">
          <View>
            <Text>No hay información disponible</Text>
          </View>
        </Page>
      </Document>
    );
  }

  // Use client-defined colors if available
  const primary = caso.clienteData.primaryColor || '#005695';
  const secondary = caso.clienteData.secondaryColor || '#0067b2';
  const styles = createStyles(primary, secondary);

  const clienteImg = caso.clienteData.imagenurl || 'https://firebasestorage.googleapis.com/v0/b/alphaplatform.appspot.com/o/noImg.png?alt=media';
  const equipoImg = caso.equipoData.imagenurl || 'https://firebasestorage.googleapis.com/v0/b/alphaplatform.appspot.com/o/noImg.png?alt=media';

  const Footer = () => (
    <View style={styles.footer} fixed>
      <Image src={alphalogo} style={styles.footerLogo} />
      <Text style={styles.footerText}>www.alphabiomedica.com</Text>
    </View>
  );

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Header */}
        <View style={styles.header} fixed>
          <Image src={clienteImg} style={styles.image} />
          <View style={styles.headerTitle}>
            <Text style={styles.headerTitleText}>REPORTE DE TECNOVIGILANCIA</Text>
          </View>
          <View style={styles.headerInfo}>
            <Text style={styles.headerInfoText}>Fecha Reporte: {caso.casoData.fechaReporte}</Text>
            <Text style={styles.headerInfoText}>Evento: {caso.casoData.fechaDelEvento}</Text>
          </View>
        </View>

        {/* Prestador */}
        <View style={styles.section}>
          <View style={styles.sectionTitle}>
            <Text style={styles.sectionTitleText}>INFORMACIÓN DEL PRESTADOR</Text>
          </View>
          {[
            { label: 'Nombre', value: caso.clienteData.nombre },
            { label: 'Ciudad', value: caso.clienteData.ciudad },
            { label: 'CC/NIT', value: caso.clienteData.ccnit },
            { label: 'Dirección', value: caso.clienteData.direccion },
            { label: 'Teléfono', value: caso.clienteData.telefono },
            { label: 'Email', value: caso.clienteData.email },
            { label: 'Sede', value: caso.clienteData.sede },
            { label: 'Código REPS', value: caso.clienteData.codigoreps },
          ].map((item, idx) => (
            <View style={styles.row} key={idx}>
              <Text style={styles.label}>{item.label}:</Text>
              <Text style={styles.value}>{item.value}</Text>
            </View>
          ))}
        </View>

        {/* Equipo */}
        <View style={styles.section}>
          <View style={styles.sectionTitle}>
            <Text style={styles.sectionTitleText}>INFORMACIÓN DEL EQUIPO</Text>
          </View>
          <View style={styles.equipmentContainer}>
            <Image src={equipoImg} style={styles.image} />
            <View style={styles.equipmentInfoContainer}>
              {[
                { label: 'Nombre', value: caso.equipoData.nombre },
                { label: 'Registro INVIMA', value: caso.equipoData.registroinvima },
                { label: 'ID Inventario', value: caso.equipoData.idinventario },
                { label: 'Estado', value: caso.equipoData.estado },
                { label: 'Marca', value: caso.equipoData.marca },
                { label: 'Modelo', value: caso.equipoData.modelo },
                { label: 'Serie', value: caso.equipoData.serie },
                { label: 'Ubicación', value: caso.equipoData.ubicacion },
                { label: 'Servicio', value: caso.equipoData.servicio },
              ].map((item, idx) => (
                <View style={styles.equipmentInfoItem} key={idx}>
                  <Text style={styles.label}>{item.label}:</Text>
                  <Text style={styles.value}>{item.value}</Text>
                </View>
              ))}
            </View>
          </View>
        </View>

        {/* Historial del Evento */}
        <View style={styles.section}>
          <View style={styles.sectionTitle}>
            <Text style={styles.sectionTitleText}>HISTORIAL DEL EVENTO</Text>
          </View>
          {[
            { label: 'Fecha Evento', value: caso.casoData.fechaDelEvento },
            { label: 'Fecha Reporte', value: caso.casoData.fechaReporte },
            { label: 'Clasificación', value: caso.casoData.clasificacionCaso },
            { label: 'Descripción', value: caso.casoData.descripcionCaso },
            { label: 'Detección', value: caso.casoData.momentoDeteccion },
            { label: 'Desenlace', value: caso.casoData.desenlaceCaso },
            { label: 'Otro', value: caso.casoData.otro },
          ].map((item, idx) => (
            <View style={styles.row} key={idx}>
              <Text style={styles.label}>{item.label}:</Text>
              <Text style={styles.value}>{item.value}</Text>
            </View>
          ))}
        </View>

        {/* Paciente */}
        <View style={styles.section}>
          <View style={styles.sectionTitle}>
            <Text style={styles.sectionTitleText}>INFORMACIÓN DEL PACIENTE</Text>
          </View>
          {[
            { label: 'Tipo Identificación', value: caso.casoData.tipoIdentificacion },
            { label: 'Número Identificación', value: caso.casoData.numeroIdentificacion },
            { label: 'Sexo', value: caso.casoData.sexo },
            { label: 'Edad', value: caso.casoData.edad },
            { label: 'Edad En', value: caso.casoData.edadEn },
            { label: 'EPS', value: caso.casoData.eps },
            { label: 'Diagnóstico Inicial', value: caso.casoData.diagnosticoInicialPaciente },
          ].map((item, idx) => (
            <View style={styles.row} key={idx}>
              <Text style={styles.label}>{item.label}:</Text>
              <Text style={styles.value}>{item.value}</Text>
            </View>
          ))}
        </View>

        {/* Gestión del Caso */}
        <View style={styles.section}>
          <View style={styles.sectionTitle}>
            <Text style={styles.sectionTitleText}>GESTIÓN DEL CASO</Text>
          </View>
          {[
            { label: 'Causa Probable', value: caso.casoData.causaCaso },
            { label: 'Acciones Iniciadas', value: caso.casoData.accionTomada },
            { label: '¿Reportó al Fabricante/Importador/Distr.?', value: caso.casoData.reporteDistibuidor },
            { label: 'Fecha Reporte Distribuidor', value: caso.casoData.fechaReporteDistribuidor },
            { label: '¿Envió al Fabricante/Importador/Distr.?', value: caso.casoData.envioDistribuidor },
            { label: 'Fecha Envío Distribuidor', value: caso.casoData.fechaEnvioDistribuidor },
            { label: 'Disponible para Evaluación', value: caso.casoData.disponibleEvaluacion },
          ].map((item, idx) => (
            <View style={styles.row} key={idx}>
              <Text style={styles.label}>{item.label}:</Text>
              <Text style={styles.value}>{item.value}</Text>
            </View>
          ))}
        </View>

        {/* Reportante */}
        <View style={styles.section}>
          <View style={styles.sectionTitle}>
            <Text style={styles.sectionTitleText}>INFORMACIÓN DEL REPORTANTE</Text>
          </View>
          {[
            { label: 'Nombre Reportante', value: caso.casoData.nombreReportante },
            { label: 'Profesión', value: caso.casoData.profesion },
            { label: 'Área', value: caso.casoData.servicioPerteneciente },
            { label: 'Dirección', value: caso.casoData.direccion },
            { label: 'Teléfono', value: caso.casoData.telefono },
            { label: 'Correo Institucional', value: caso.casoData.correoInstitucional },
            { label: 'Estado Reporte', value: caso.casoData.estadoReporte === 'A' ? 'Abierto' : 'Cerrado' },
          ].map((item, idx) => (
            <View style={styles.row} key={idx}>
              <Text style={styles.label}>{item.label}:</Text>
              <Text style={styles.value}>{item.value}</Text>
            </View>
          ))}
        </View>

        <Footer />
      </Page>
    </Document>
  );
};

export default ReporteTecnoDocuPDF;
